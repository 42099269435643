import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { connect } from "react-redux";
import { dispatchFoucs } from "../../../redux/action";

function TreeNode(props) {
    const [expanded, setExpanded] = useState(false);

    const toggleExpansion = (expanded) => {
        setExpanded(!expanded);
    };

    const lineFocus = (val) => {
        props.setActiveItemId(val);
        props.dispatchFoucs(val)
    }

    return (
        <li>
            {props.node.subsections?.length === 0 ? <div className={props.activeItemId === props.node.linenumber ? "active no-tree-parent" : "no-tree-parent"}><p title={props.node.title} onClick={() => lineFocus(props.node)}><button>{props.node.title}</button></p></div> :
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <div className={props.activeItemId === props.node.linenumber ? "tree-parent active" : "tree-parent"}><Button onClick={() => toggleExpansion(expanded)} aria-label={expanded ? 'expand' : 'collapse'}> {expanded ? <FaChevronDown /> : <FaChevronRight />}</Button> <p title={props.node.title} ><button onClick={() => lineFocus(props.node)}>{props.node.title}</button></p></div>}

            {expanded && (
                <ul>
                    {props.node.subsections.map((child, index) => (
                        <TreeNode key={child.id} node={child} dispatchFoucs={props.dispatchFoucs} activeItemId={props.activeItemId} setActiveItemId={props.setActiveItemId} />
                    ))}
                </ul>
            )}
        </li>
    );
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchFoucs: (isNumber) => {
            dispatchFoucs(dispatch, isNumber)
        },
    }
}
export default connect(null, mapDispatchToProps)(TreeNode);

