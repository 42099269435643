// https://www.overleaf.com/learn/latex/Creating_a_document_in_LaTeX#Reference_guide

// TODO: more class names
export const classNames = [
  'article',
  'report',
  'book',
  'letter',
  // 'slides',
  'beamer',
]
