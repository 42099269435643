import { buildIncludeCompletions } from './include.ts'
import { buildLabelCompletions } from './labels.ts'
import { buildPackageCompletions } from './packages.ts'
import { buildSnippetCompletions } from './snippets.ts'
import { buildEnvironmentCompletions } from './environments.ts'
import { buildReferenceCompletions } from './references.ts'
import { buildClassCompletions } from './classes.ts'
import { Completions } from './types.ts'
import { buildBibliographyStyleCompletions } from './bibliography-styles.ts'
import { CompletionContext } from '@codemirror/autocomplete'

export const buildAllCompletions = (
  completions: Completions,
  context: CompletionContext
) => {
  buildSnippetCompletions(completions)
  buildEnvironmentCompletions(completions)
  buildClassCompletions(completions)
  buildBibliographyStyleCompletions(completions)
  buildIncludeCompletions(completions, context)
  buildReferenceCompletions(completions, context)
  buildLabelCompletions(completions, context)
  buildPackageCompletions(completions, context)

  return completions
}
