import { combineReducers } from 'redux';
import RightSidebar from "./rightsidebar";
import LayoutReducer from "./layoutreducer";
import LeftSideBar from './leftsidebar';

const rootReducer = combineReducers({
    RightSidebar: RightSidebar,
    LayoutReducer: LayoutReducer,
    LeftSideBar: LeftSideBar
});

export default rootReducer;