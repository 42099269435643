import React, { useState } from 'react';
import Layout from "./components/layout";
import './style/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Joyride, { ACTIONS, EVENTS, ORIGIN, STATUS, CallBackProps } from 'react-joyride';


const StepsDesc = [
    {
        content: <div className='first-slide'><h2>Welcome to iAuthor LaTeX - Online proofing tool</h2>
            <p>Please take a quick tour to help familiarize  yourself with tool.</p></div>,
        locale: { skip: <strong aria-label="skip">Skip</strong> },
        placement: 'center',
        target: 'body',
    },
    {
        content: <p>After making edits, click the Compile button to generate a PDF that reflects all changes. The PDF will appear next to the editor for easy reference.</p>,
        placement: 'bottom',
        styles: {
            options: {
                width: 400,
            },
        },
        target: '.firststep',
        title: 'Compile',
    },
    {
        content: <p>Use this functionality if you want to communicate information to the Editor/ typesetter, or to instruct changes you are unable to make.</p>,
        placement: 'left',
        styles: {
            options: {
                width: 400,
            },
        },
        target: '.secondstep',
        title: 'Comment',
    },
    {
        content: <p>Please review all the AQs, you must either reply or accept each before the article can be submitted.</p>,
        placement: 'left',
        styles: {
            options: {
                width: 400,
            },
        },
        target: '.thirdstep',
        title: 'Author Query',
    },
    {
        content: <p>To save a version of the current document, use <i>Download PDF</i>. The PDF will have the track changes that allows you to share, review, or archive a copy.</p>,
        placement: 'left',
        styles: {
            options: {
                width: 400,
            },
        },
        target: '.fourthstep',
        title: 'Download PDF',
    },
    {
        content: <p>The modifications you make to the article in iAuthor can be viewed in the track changes panel. The panel also offers the opportunity to reject your changes. You can use the dropdown to filter the changes made by the respective user.</p>,
        placement: 'left',
        styles: {
            options: {
                width: 400,
            },
        },
        target: '.fifthstep',
        title: 'Track Changes',
    },
    {
        content: <p>Click on the attachment icon to upload files for replacement images or supplementary files in any format.</p>,
        placement: 'right',
        styles: {
            options: {
                width: 400,
            },
        },
        target: '.sixthstep',
        title: 'Attachment',
    },
    {
        content: <p>Access any files provided along with the document through <i>Supplementary</i>    Files to review or download as needed.</p>,
        placement: 'right',
        styles: {
            options: {
                width: 400,
            },
        },
        target: '.seventhstep',
        title: 'Supplementary files',
    },
    {
        content: <p>You can view high-resolution images here, which will be used in the final publication.</p>,
        placement: 'right',
        styles: {
            options: {
                width: 400,
            },
        },
        target: '.eigthstep',
        title: 'Images',
    },
    {
        content: <p>Version history - Use Version History to access previous versions of the document, which allows to view and download for reference.</p>,
        placement: 'right',
        styles: {
            options: {
                width: 400,
            },
        },
        target: '.ninthstep',
        title: 'Version history',
    },
    {
        content: <p> Once you have made all required changes and answered all author queries, please select "Submit" to allow your article to move to the next stage of the process.<br></br>
        Please Note: You will unable to make further changes to the article once it is submitted.</p>,
        placement: 'left',
        styles: {
            options: {
                width: 400,
            },
        },
        target: '.tenthstep',
        title: 'Submit',
    },
    // {
    //     content: <h2>Let's all folks</h2>,
    //     placement: 'center',
    //     target: 'body',
    // },
];
function Home(props) {
    const [run, setRun] = useState(false);
    const [steps] = useState(StepsDesc);
    const [stepIndex, setStepIndex] = useState(0);

    const handleJoyrideCallback = (data: CallBackProps) => {
      const { action, index, origin, status, type } = data;
  
      if (action === ACTIONS.CLOSE && origin === ORIGIN.KEYBOARD) {
        // do something
      }
  
      if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
        // Update state to advance the tour
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        // You need to set our running state to false, so we can restart if we click start again.
        setRun(false);
      }
  
      console.groupCollapsed(type);
      console.log(data); //eslint-disable-line no-console
      console.groupEnd();
    };
    const StartTour = (val) => {
        setTimeout(() => {
            setRun(val)
        }, [2000])
    }

    const handleHelp = (value) => {
        setRun(true);
        setStepIndex(0);
    }

    return (
        <>
            <Joyride
                steps={steps}
                run={run}
                continuous={true}
                scrollToFirstStep={false}
                showProgress={true}
                showSkipButton={true}
                callback={handleJoyrideCallback} 
                stepIndex={stepIndex}
            />
            <Layout StartTour={StartTour} handleHelp={handleHelp} />
            <ToastContainer />
        </>
    )
}

export default Home;
