import React, { useEffect, useRef } from 'react';
import { pdfjs } from 'react-pdf';

// Define the function to extract text data
const extractTextData = (textContent) => {
  if (!textContent || !Array.isArray(textContent.items)) {
    throw new Error('Invalid textContent format');
  }

  return textContent.items.map(item => (
    {
      text: item.str,
      fontName: item.fontName,
      height: item.height,
      width: item.width,
      transform: item.transform
    }));
};

const NonWhitespaceRegexp = /\S/;

const isAllWhitespace = (str) => !NonWhitespaceRegexp.test(str);

const TextLayerBuilder = ({ textContent, viewport, onLineTextClick }) => {
  const textLayerDivRef = useRef(null);

  useEffect(() => {
    // Add CSS for text selection color
    const style = document.createElement('style');
    style.textContent = `
      .textLayer span::selection {
        background: skyblue;
      }
      .textLayer span {
        cursor: text;
        user-select: text;
      }
    `;
    document.head.appendChild(style);

    if (!textContent || !viewport || !textLayerDivRef.current) return;

    const textLayerDiv = textLayerDivRef.current;
    textLayerDiv.innerHTML = '';

    // Set precise container dimensions
    textLayerDiv.style.width = `${viewport.width}px`;
    textLayerDiv.style.height = `${viewport.height}px`;
    textLayerDiv.style.transformOrigin = '0 0';

    const appendText = (geom) => {
      if (isAllWhitespace(geom.text)) return;

      // Skip rendering if text is "UNCORRECTED PROOF"
      if (geom.text.trim() === "UNCORRECTED PROOF") return;

      const textDiv = document.createElement('span');
      const tx = pdfjs.Util.transform(viewport.transform, geom.transform);

      // Extract exact font metrics from PDF
      const fontScale = Math.sqrt((tx[0] * tx[0]) + (tx[1] * tx[1]));
      const rotation = Math.atan2(tx[1], tx[0]);
      const ascent = geom.ascent || 0;
      const descent = geom.descent || 0;
      const verticalAlign = -ascent * fontScale;

      // Apply precise PDF styling
      Object.assign(textDiv.style, {
        position: 'absolute',
        left: `${tx[4]}px`,
        top: `${tx[5] + verticalAlign}px`,
        fontSize: `${fontScale}px`,
        fontFamily: geom.fontName,
        transform: `scaleX(${tx[0] / fontScale}) rotate(${rotation}rad)`,
        transformOrigin: 'left bottom',
        whiteSpace: 'pre',
        lineHeight: `${(ascent - descent) * fontScale}px`,
        fontWeight: geom.fontWeight || 'normal',
        fontStyle: geom.isItalic ? 'italic' : 'normal',
        letterSpacing: `${geom.charSpacing || 0}px`
      });

      textDiv.textContent = geom.text;

      textDiv.addEventListener('click', (event) => {
        let clickedText = event.target.textContent.trim()
          .replace(/\s?\[.*?\]\s?/g, ' ')
          .replace(/\s+/g, ' ')
          .replace(/^\d+(\.\d+)*\.\s*/g, '')
          .trim();

        if (clickedText.split(' ').length > 7) {
          clickedText = clickedText.split(' ').slice(1, -1).join(' ');
        }

        onLineTextClick?.(clickedText);
      });

      textLayerDiv.appendChild(textDiv);
    };

    extractTextData(textContent).forEach(appendText);
  }, [textContent, viewport, onLineTextClick]);

  return <div ref={textLayerDivRef} className="textLayer" />;
};


export default TextLayerBuilder;
