import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

const Captcha = (props) => {
    const navigate = useNavigate();
    const captcha = useRef(null);

    const handleCaptchaVerify = () => {
        if (captcha.current.getValue()) {
            props.CaptchaVerify(true)
        } else {
            console.log("Captcha not verified");
        }
    };

    return (
        <div className="captcha-container">
            <div className="recaptcha-box">
                <ReCAPTCHA
                    ref={captcha}
                    sitekey="6LeaQ_IpAAAAAOfA3sfU_H6hSnUdXIZIhyLFTkbQ"
                    onChange={handleCaptchaVerify}
                    size="normal"
                />
            </div>
        </div>
    )
}
export default Captcha;