import NoFound from "../../images/no-data.png";

const NodataFound = () => {
    return (
        <div className="nodata-found">
            <img src={NoFound} alt="No Data Found" />
            <p>No Data Found</p>
        </div>
    )
}
export default NodataFound;