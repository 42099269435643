
import { toast } from 'react-toastify';

export const toastError = (msg) => toast.error(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
});
export const toastSuccess = (msg) => toast.success(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
});
export const toastWarning = (msg) => toast.warning(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true
});