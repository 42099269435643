import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import TreeNode from "./list";
import { toastError, toastSuccess } from '../../toaster';
import { connect } from "react-redux";

const TOC = (props) => {
  const [tocData, setTocdata] = useState([]);
  const [activeItemId, setActiveItemId] = useState(null);

  useEffect(() => {
    if (props.isDocvalue !== null) {
      TOCFinder(props.isDocvalue.state.doc)
    }
  }, [props]);

  const TOCFinder = (value) => {
    try {
      const doc = value;
      const lineCount = doc.lines;
      const extractedSections = [];
      const sectionRegex = /\\section\{((?:\\[a-zA-Z]+\{[^}]*\}|[^\\{}])*)\}/g;
      const subsectionRegex = /\\subsection\{((?:\\[a-zA-Z]+\{[^}]*\}|[^\\{}])*)\}/g;
      const subsubsectionRegex = /\\subsubsection\{((?:\\[a-zA-Z]+\{[^}]*\}|[^\\{}])*)\}/g;
      let currentSection = null;
      let currentSubsection = null;
      let hasError = false;

      for (let i = 1; i <= lineCount; i++) {
        try {
          const lineContent = doc.line(i).text;
          let match;

          while ((match = sectionRegex.exec(lineContent)) !== null) {
            currentSection = {
              linenumber: i,
              type: 'section',
              title: match[1].replace(/\\[a-zA-Z]+\{([^}]*)\}/g, '$1'),
              subsections: []
            };
            extractedSections.push(currentSection);
            currentSubsection = null;
          }

          while ((match = subsectionRegex.exec(lineContent)) !== null) {
            if (currentSection) {
              currentSubsection = {
                linenumber: i,
                type: 'subsection',
                title: match[1].replace(/\\[a-zA-Z]+\{([^}]*)\}/g, '$1'),
                subsections: []
              };
              currentSection.subsections.push(currentSubsection);
            }
          }

          while ((match = subsubsectionRegex.exec(lineContent)) !== null) {
            if (currentSubsection) {
              currentSubsection.subsections.push({
                linenumber: i,
                type: 'subsubsection',
                title: match[1].replace(/\\[a-zA-Z]+\{([^}]*)\}/g, '$1'),
                subsections: []
              });
            }
          }
        } catch (error) {
          if(!hasError){
          toastError(`Error processing line ${i}: ${error.message}`);
          }
        }
      }
      setTocdata(extractedSections);
    } catch (error) {
      toastError(`Error generating table of contents: ${error.message}`);
      setTocdata([]);
    }
  };


  return (
    <div className="toc-area">
      <div className="slider-header">
        <h5>File outline</h5>
        <div>
          <button className="close-btn" onClick={props.close} title="Close">
            <FaTimes />
          </button>
        </div>
      </div>
      <div className="toc-area-inner">
        <ul className="treenode">
          {tocData.map((item, index) =>
            <TreeNode key={item.id || index} node={item} index={index} activeItemId={activeItemId} setActiveItemId={setActiveItemId} />
          )}
        </ul>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    isTocData: state.LeftSideBar.layoutData,
    isDocvalue: state.LayoutReducer.Docvalue
  }
}


export default connect(mapStateToProps, null)(TOC);
