import { useState } from "react";
import { FaTimes, FaRegFile, FaPlus, FaEllipsisV } from "react-icons/fa";

const FilelistTab = (props) => {
    const [fileContent, setFileContent] = useState('');
    const [fileTree, setFileTree] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [fileSelect, setFileselect] = useState(0);
    const [showDeleteDropdown, setShowDeleteDropdown] = useState(false);
    const [deleteFileIndex, setDeleteFileIndex] = useState(null);

    const openFileExplorer = () => {
        document.getElementById('fileInput').click();
    };

    const handleFileOpen = async (event) => {
        try {
            if (event.target !== undefined) {
                const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setFileContent(e.target.result);
                    };
                    reader.readAsText(file);
                    const fileName = file.name;
                    const filePath = file.webkitRelativePath || file.name;
                    const fileTreeItem = { name: fileName, path: filePath, value: reader };
                    setFileTree([...fileTree, fileTreeItem]);
                    setCurrentFile(file); // Set the current file
                    setFileselect(fileTree.length);
                    setTimeout(() => {
                        props.editorData(fileTreeItem);
                    }, 100);
                }
            }
        } catch (error) {
            console.log(error, 'error');
        }
    };

    const fileHandle = (tab, val) => {
        setFileselect(tab);
        setShowDeleteDropdown(!showDeleteDropdown);
        setFileContent(val.value.result); // Update file content
        props.editorData(val, '');
    };

    
    const toggleDeleteDropdown = (index) => {
        if (deleteFileIndex === index) {
            setShowDeleteDropdown(!showDeleteDropdown);
        } else {
            setShowDeleteDropdown(true);
            setDeleteFileIndex(index);
        }
    };

    const handleDeleteFile = () => {
        if (deleteFileIndex !== null) {
            const updatedFileTree = fileTree.filter((_, index) => index !== deleteFileIndex);
            setFileTree(updatedFileTree);

            // Clear the editor if the current file is deleted
            if (fileSelect === deleteFileIndex) {
                setFileContent('');
                setCurrentFile(null);
                setFileselect(null);
                props.editorData(null);
            }

            setShowDeleteDropdown(false);
            setDeleteFileIndex(null);
        }
    };

    return (
        <div className="file-list-area">
            <div className="slider-header">
                <h5>File</h5>
                <div style={{ width: '40px' }}>
                    <button className="upload-icons" onClick={openFileExplorer}><FaPlus /></button>
                    <button className="close-btn" onClick={props.close} title="Close">
                        <FaTimes/>
                    </button>
                </div>
            </div>
            <div className="file-list">
                <ul>
                    {fileTree.map((item, index) =>
                        <li key={index}>
                            <p className={index === fileSelect ? 'active' : ''} onClick={() => fileHandle(index, item)}>
                                <FaRegFile />
                                <span>{item.name}</span>
                                <FaEllipsisV className="three-dots" onClick={() => toggleDeleteDropdown(index)} />
                            </p>
                            {showDeleteDropdown && deleteFileIndex === index &&
                                <button className="delete-btn">
                                    <span onClick={handleDeleteFile} >Delete</span>
                                </button>
                            }
                        </li>
                    )}
                </ul>
            </div>
            <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileOpen} />
        </div>
    );
};

export default FilelistTab;
