import { LayoutData } from "../action/action";

const initialState = {
    layoutData: []
};

const LeftSideBar = (state = initialState, action) => {
    switch (action.type) {
        case LayoutData:
            return { ...state, layoutData: action.tocData };
        default:
            return state;
    }
}

export default LeftSideBar;