import { useState, useEffect } from "react";
import { FaTimes, FaReply, FaPencilAlt, FaRegTrashAlt, FaFileAlt } from "react-icons/fa";
import CommentBox from "./commments/CommentBox";
import { Modal, Button, Row, Col, Dropdown, Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { dispatchComment, dispatchFoucs, dispatchdelete, dispatchBlobcomment } from "../../redux/action";
import { v4 as uuidv4 } from 'uuid';
import { toastError, toastSuccess } from '../toaster';
import { useLocation, useNavigate } from "react-router-dom";
import pathconfig from '../../pathconfig.json';
import packjson from '../../../package.json';
import axios from "axios";
const generateUniqueId = () => {
    return uuidv4().slice(0, 3); // Generate a 3-letter unique ID
};
const handleError = (error, message = error || 'Something went wrong') => {
    console.error(error);
    toastError(message);
};
const CommentTab = (props) => {
    const [comments, setComments] = useState([]);
    const [commentsAll, setCommentsAll] = useState([]);
    const [replyingIndex, setReplyingIndex] = useState(null);
    const [replyText, setReplyText] = useState('');
    const [showCommentInput, setShowCommentInput] = useState(false);
    const [deletedComment, setdeletedComment] = useState('');
    const [repliedComments, setRepliedComments] = useState({});
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedCommentText, setEditedCommentText] = useState('');
    const [commentBlob, setCommentBlob] = useState([]);
    const [showconfirm, setConfirm] = useState(false)
    const [deleteValue, setDeleteValue] = useState({})
    const [profile, setProfile] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    //just for testing build
    useEffect(() => {
        const hasCommentsChanged = (prevComments, newComments) => {
            // Implement a deep comparison logic here
            return JSON.stringify(prevComments) !== JSON.stringify(newComments);
        };
        if (hasCommentsChanged(props.isCommentData.comment, comments)) {
            // let commentAdded =comments.map((item) => item.ActivityID === undefined ? props.isDocvalue.ActivityID : item.ActivityID);
            let CommentsChange = { comment: comments, changeIndex: null }
            props.dispatchComment(CommentsChange);
        }
    }, [comments, props.isCommentData.comment]);


    useEffect(() => {
        setProfile(props.isDocDetails)
    }, [props.isDocDetails]);

    useEffect(() => {
        if (commentBlob.length > 0) {
            const filterBlob = commentBlob.filter(item => item?.comment !== '')
            const filterFromComment = filterBlob.filter((itemB) =>
                comments.some((itemC) => itemC.cid === itemB.cid)
            )
            props.dispatchBlobcomment(filterFromComment);
        }
    }, [commentBlob]);

    useEffect(() => {
        if (props?.isCommentData?.comment !== undefined && props?.isCommentData?.comment.length > 0) {
            setComments(props?.isCommentData?.comment);
            setCommentsAll(props?.isCommentData?.comment);
        }
    }, [props?.isCommentData]);

    useEffect(() => {
        if (props?.isCommentValue.length !== 0) {
            setCommentBlob(props?.isCommentValue)
        }
    }, [props?.isCommentValue])

    useEffect(() => {
        if (Object.keys(props?.isBlobComment).length !== 0 && comments.length > 0) {
            const updatedComments = comments.map((itemA) => {
                const matchingBlob = props?.isBlobComment.find((itemB) => itemB.cid === itemA.cid);
                return matchingBlob
                    ? {
                        ...itemA,
                        from: matchingBlob.from,
                        to: matchingBlob.to,
                        linenumber: props?.isDocvalue.state.doc.lineAt(matchingBlob.from).number,
                    }
                    : itemA;
            });
            // Check if the comments have actually changed before updating the state
            if (JSON.stringify(comments) !== JSON.stringify(updatedComments)) {
                setComments(updatedComments);
                setCommentsAll(updatedComments);
            }
        }
    }, [props?.isBlobComment, props?.isDocvalue]);


    const handleSaveComment = (commentText) => {
        try {
            if (props?.isCommentSidebar?.selectValue.selectText !== '') {
                if (commentText) {
                    const comment = {
                        Username: profile.username, // Replace with actual username
                        role: profile.role,
                        ActivityID: profile.ActivityID,
                        cid: generateUniqueId(), // Generate a unique ID for the comment
                        Date_Time: new Date().toLocaleString(),
                        Cmdcomtents: commentText.comment,
                        reply: [], // Initialize reply as an empty array
                        Attachments: commentText.fileattached,
                        ReplyRequire: false,
                        status: '',
                        isTrack: false,
                        trackType: 'none',
                        'element-type': 'Comments',
                        cmdtype: 'Comments',
                        orderid: 1,
                        NoActionRequired: false,
                        sequencenumber: '',
                        linenumber: props?.isCommentSidebar?.selectValue.linenumber,
                        selectText: props?.isCommentSidebar?.selectValue.selectText,
                        from: props?.isCommentSidebar?.selectValue.fromLine,
                        to: props?.isCommentSidebar?.selectValue.toLine,
                    };
                    setComments([...comments, comment]);
                    setCommentsAll([...comments, comment])
                    setShowCommentInput(true);
                    props.dispatchFoucs(comment);
                }
            } else {
                toastError('Please select a word or text to insert a comment..')
            }
        } catch (error) {
            handleError(error, 'Error saving comment');
        }
    };

    const handleConfirmDelete = (e, index, replyIndex = null) => {
        setDeleteValue({ 'index': index, 'replyValue': replyIndex })
        setConfirm(true)
    }

    // const handleDeleteComment = () => {debugger
    //     const updatedComments = [...comments];
    //     let deletedComment;
    //     if (deleteValue.replyValue === null) {
    //         // Deleting a main comment
    //         deletedComment = updatedComments.splice(deleteValue.index, 1)[0]; // Get and remove the deleted comment
    //         setRepliedComments({ ...repliedComments, [deleteValue.index]: false });
    //     } else {
    //         // Deleting a reply
    //         deletedComment = updatedComments[deleteValue.index].reply.splice(deleteValue.replyValue, 1)[0]; // Get and remove the deleted reply
    //         if (updatedComments[deleteValue.index].reply.length === 0) {
    //             setRepliedComments({ ...repliedComments, [deleteValue.index]: false });
    //         }
    //     }
    //     setComments(updatedComments);
    //     setCommentsAll(updatedComments);
    //     props.dispatchdelete(deletedComment)
    //     setdeletedComment(deletedComment);
    //     setConfirm(false)
    //     toastSuccess('Messege delete successfully.')
    // };

    const handleDeleteComment = () => {
        try {
            const updatedComments = comments.filter((comment, index) => {
                if (index === deleteValue.index) {
                    if (deleteValue.replyValue === null) {
                        // Deleting a main comment
                        return false; // Remove this comment
                    } else {
                        // Deleting a reply
                        comment.reply = comment.reply.filter((_, replyIndex) => replyIndex !== deleteValue.replyValue);
                        return true; // Keep the main comment, but with updated replies
                    }
                }
                return true; // Keep all other comments
            });
            const deletedComment = deleteValue.replyValue === null
                ? comments[deleteValue.index]
                : comments[deleteValue.index].reply[deleteValue.replyValue];
            setComments(updatedComments);
            setCommentsAll(updatedComments);
            props.dispatchdelete(deletedComment);
            setdeletedComment(deletedComment);
            setConfirm(false);
            toastSuccess('Message deleted successfully.');
        } catch (error) {
            handleError(error, 'Error deleting comment');
        }
    };

    const handleReplyButtonClick = (e, index) => {
        e.stopPropagation()
        setReplyingIndex(index);
        setReplyText('');
    };

    const handleSendReply = (e, commentIndex) => {
        e.stopPropagation();
        try {
            if (e.key === 'Enter' && replyText) {
                const reply = {
                    Username: profile.username, // Replace with actual username
                    role: profile.role,
                    ActivityID: profile.ActivityID,
                    Date: new Date().toLocaleDateString(),
                    Time: new Date().toLocaleTimeString(),
                    rpid: generateUniqueId(), // Generate a unique ID for the reply
                    Date_Time: new Date().toLocaleString(),
                    rplycomtents: replyText,
                    newcmnd: true,
                    status: '',
                    sequencenumber: '',
                    cmdtype: 'ReplyCmnt',
                    orderid: 1,
                    id: comments[commentIndex].cid,
                    NoActionRequired: false,
                };
                const updatedComments = [...comments];
                updatedComments[commentIndex].reply.push(reply);
                updatedComments[commentIndex].Date = new Date().toLocaleDateString(); // Update Date
                updatedComments[commentIndex].Date_Time = new Date().toLocaleString(); // Update Date_Time
                setComments(updatedComments);
                setCommentsAll(updatedComments);
                setReplyingIndex(null)
                setReplyText('');
                setRepliedComments({ ...repliedComments, [commentIndex]: true });
                setTimeout(() => {
                    let CommentsChange = { comment: comments, changeIndex: commentIndex }
                    props.dispatchComment(CommentsChange);
                }, 1000);
            }
        } catch (error) {
            handleError(error, 'Error sending reply');
        }
    };

    const handleCancelReply = () => {
        setReplyingIndex(null);
        setReplyText('');
    }

    const handleCommentClick = (commentId) => {
        props.dispatchFoucs(commentId);
    };

    const handleEditComment = (e, index) => {
        e.stopPropagation()
        setEditingIndex(index);
        setEditedCommentText(comments[index].Cmdcomtents);
    };

    const handleSaveEditedComment = (e, index) => {
        if (e.key === 'Enter' && editedCommentText) {
            const updatedComments = [...comments];
            updatedComments[index].Cmdcomtents = editedCommentText;
            updatedComments[index].Date = new Date().toLocaleDateString(); // Update Date
            updatedComments[index].Date_Time = new Date().toLocaleString(); // Update Date_Time
            setComments(updatedComments);
            setCommentsAll(updatedComments);
            setEditingIndex(null);
            setEditedCommentText('');
        }
    };

    const deleteConfirm = (value, indexvalue) => {
        try {
            props.getTrigger(false)
            let GetDetailDataString = localStorage.getItem(`${profile.docid}_init-getdetails`);
            let GetDetailData = JSON.parse(GetDetailDataString);
            let data = {
                "ArticleGUID": value.ArticleGUID,
                "AttachID": value.AttachID,
                "Proof_Type": GetDetailData.jobdetails.ProofType,
                "AttachFlag": value.AttachFlag,
                "docid": profile.docid
            }
            const deleteUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].deleteAttachment}`;
            axios.post(deleteUrl, data).then((response) => {
                toastSuccess(`${response.data.data[0].Filename} - ${response.data.message}`)
                props.getTrigger(true)
            }
            ).catch((error) => {
                console.log(error);
                toastError(error.message);
                //navigate(`/notification?docid=${profile.docid}`, { state: 'error' })
            });
            const attachmentDelete = [...comments];
            attachmentDelete[indexvalue].Attachments = [];
            setComments(attachmentDelete);
            setCommentsAll(attachmentDelete);
        } catch (error) {
            handleError(error, 'Error confirming deletion');
            props.getTrigger(true);
        }
    }

    const handleFocus = (e) => {
        e.stopPropagation()
    };

    const getTriggerfile = () => {
        props.getTrigger(true)
    }

    const handleSelect = (e) => {
        try {
            if (e.target.value === 'all') {
                setCommentsAll(comments)
            } else {
                let filter = comments.filter((item) => item.role === e.target.value)
                setCommentsAll(filter)
            }
        } catch (error) {
            handleError(error, 'Error filtering comments');
        }
    }
    useEffect(() => {
        if (props?.trackComment.length !== 0) {
            setComments(props?.trackComment);
            setCommentsAll(props?.trackComment)
        }
    }, [props?.trackComment])

    return (
        <div className="comment-area">
            <div className="slider-header">
                <h5>Comment - ({comments.length})</h5>
                <div style={{ width: '20px' }}>
                    <FaTimes onClick={props.close} />
                </div>
            </div>
            <div className={`modal-outer ${showconfirm ? 'active' : ''}`}>
                <div className="modal confirmation-popup">
                    <div className="modal-body">
                        <h1>Are you want to delete this comment?</h1>
                    </div>
                    <div className="modal-footer">
                        <button className="primary" onClick={handleDeleteComment}>Yes</button>
                        <button className="outline-secondary" onClick={() => setConfirm(false)}>No</button>
                    </div>
                </div>
            </div>
            <div className="comment-group">
                <div className="comment-note">
                    <FaFileAlt /> <p>Note: Please select a word or text to insert a comment</p>
                </div>
                <CommentBox onSaveComment={handleSaveComment} onCancelComment={() => setReplyingIndex(null)} getTriggerfile={getTriggerfile} />
                <div className="filterTop">
                    <label>Filter:</label>
                    <select aria-label="Default select example" onChange={(e) => handleSelect(e)}>
                        <option value="all">All</option>
                        <option value="Author">Author</option>
                        <option value="Editor">Editor</option>
                        <option value="Master Copier">Master Copier</option>
                        <option value="Copy Editor">Copy Editor</option>
                    </select>
                </div>
                <div className="comment-container">
                    {commentsAll.length > 0 && commentsAll.map((comment, index) => {
                        const attachmentContent = Array.isArray(comment.Attachments) && comment.Attachments.length > 0 ? (
                            <div className='comment-attached' style={{ marginTop: '6px' }}>
                                {comment.Attachments.map((item, attachmentIndex) => (
                                    <div className='comment-attached-list' key={`attachment-${index}-${attachmentIndex}`}>
                                        <p>{item.Filename}</p>
                                        <label>size - {item.Filesize}</label>
                                        <button onClick={() => deleteConfirm(item, index)} onKeyDown={(e) => e.key === 'Enter' && deleteConfirm(item, index)}><FaRegTrashAlt /></button>
                                    </div>
                                ))}
                            </div>
                        ) : null
                        return (
                            <div key={`comment-${index}`} className="comment-display">
                                <div className="comment-inner">
                                    <div onClick={() => handleCommentClick(comment)} onKeyDown={(e) => e.key === 'Enter' && handleCommentClick(comment)} tabIndex={0} role="button">
                                        <h5>{comment.Username} <label>{comment.Date_Time}</label></h5>
                                        {editingIndex === index ? (
                                            <textarea
                                                type="text"
                                                onFocus={(e) => handleFocus(e)}
                                                value={editedCommentText}
                                                onChange={(e) => setEditedCommentText(e.target.value)}
                                                onKeyDown={(e) => handleSaveEditedComment(e, index)}
                                            />
                                        ) : (
                                            <p dangerouslySetInnerHTML={{ __html: comment.Cmdcomtents }}></p>
                                        )}
                                        {attachmentContent}
                                    </div>
                                    <div className="comment-footer">
                                        <Button onClick={(e) => handleEditComment(e, index)} className="accept-btn" title="Edit"><FaPencilAlt /> Edit</Button>
                                        <Button onClick={(e) => handleReplyButtonClick(e, index)} className="accept-btn" title="Reply"><FaReply /> Reply</Button>
                                        <Button onClick={(e) => handleConfirmDelete(e, index)} className="reject-btn" title="Delete"><FaRegTrashAlt /> Delete</Button>
                                    </div>
                                    {comment.reply && comment.reply.length > 0 && (
                                        <div className="comment-inner reply-area" >
                                            {comment.reply.map((reply, replyIndex) => (
                                                <div key={`reply-${index}-${replyIndex}`} className="reply-inner">
                                                    <h5>{reply.Username}  <label>{comment.Date_Time}</label></h5>
                                                    <p>
                                                        {reply.rplycomtents}
                                                    </p>
                                                    <div className="comment-footer">
                                                        <Button onClick={(e) => handleConfirmDelete(e, index, replyIndex)} className="reject-btn"><FaRegTrashAlt /> Delete</Button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {replyingIndex === index && (
                                        <div className="reply-area">
                                            <textarea
                                                value={replyText}
                                                placeholder="hit Enter to reply"
                                                onChange={(e) => setReplyText(e.target.value)}
                                                onKeyDown={(e) => handleSendReply(e, index)}
                                            />
                                            <div className="comment-footer">
                                                <Button onClick={handleCancelReply} className="reject-btn">Cancel</Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isCommentSidebar: state.RightSidebar.commentSidebar,
        isFocusCommentTab: state.RightSidebar.FocusCommentTab,
        isCommentData: state.RightSidebar.Comments,
        isCommentValue: state.RightSidebar.CommentValue,
        isBlobComment: state.RightSidebar.blobCommentValue,
        isDocvalue: state.LayoutReducer.Docvalue,
        isDocDetails: state.LayoutReducer.DocDetails
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchFoucs: (isNumber) => {
            dispatchFoucs(dispatch, isNumber)
        },
        dispatchComment: (value) => {
            dispatchComment(dispatch, value)
        },
        dispatchdelete: (deletedComment) => {
            dispatchdelete(dispatch, deletedComment);
        },
        dispatchBlobcomment: (blobComment) => {
            dispatchBlobcomment(dispatch, blobComment)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CommentTab);