import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
function Main() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

let MainPage = document.getElementById("main");
const root = ReactDOM.createRoot(MainPage);
root.render(<Main />);
