import { useState, useEffect } from "react";
import { FaTimes, FaCheck } from "react-icons/fa";
import { connect } from "react-redux";
import { dispatchTrackAction, dispatchTrackchanges, dispatchTrackBlob, dispatchFoucs } from "../../redux/action";
import { Form } from 'react-bootstrap';
import NodataFound from "../nodata";
const TrackChanges = (props) => {
    const [trackChange, setTrackchange] = useState([]);
    const [trackChangeData, setTrackchangeData] = useState([]);
    const [allTrackData, setAllTrackData] = useState([]);

    useEffect(() => {
        if (props.isTrackChange.length !== 0) {
            // let dataChange = props.isTrackChange.map((item) => {
            //     return {
            //         ...item,
            //         Username: props.userProfile.username, // Replace with actual username
            //         role: props.userProfile.role,
            //         Date_Time: item.type === 'insertion'
            //             ? JSON.parse(item.decoration?.spec.attributes['data-meta']).Date_Time
            //             : JSON.parse(item.decoration?.spec.metadata).Date_Time,
            //         cmdtype: 'trackchanges',
            //         status: ''
            //     }
            // })
            // let filter = dataChange.filter((item) => item.type === 'deletion' || item.type === 'insertion')
            setTrackchangeData(props.isTrackChange)
            setTrackchange(props.isTrackChange)
            // props.trackChangesValue(filter)
            // setTimeout(() => {
            //     props.dispatchTrackAction(filter)
            // }, 5000);
            console.log(props.isTrackChange, 'props.isTrackChange')
        } else {
            setTrackchangeData([])
            setTrackchange([])
        }
    }, [props.isTrackChange])

    // useEffect(() => {

    //     // console.log(allTrackData, 'all')
    //     // const mergedArray = allTrackData.concat(trackChangeData);
    //     // //setAllTrackData(allTrackData, ...trackChangeData)
    //     // console.log(mergedArray, 'merge')
    //     // // const mergedArray = Array.from(new Set(allTrackData.concat(trackChangeData)));
    //     // setAllTrackData(mergedArray);
    //     // setTrackchange(mergedArray);
    //     // props.trackChangesValue(mergedArray);
    // }, [trackChangeData])

    useEffect(() => {
        setAllTrackData(props.isTrackData)
    }, [props.isTrackData])

    // useEffect(() => {
    //     if (trackChangeData) {
    //         let dataChange = trackChangeData.map((item) => {
    //             return {
    //                 ...item,
    //                 Username: props.userProfile.username, // Replace with actual username
    //                 Date_Time: new Date().toLocaleString(),
    //                 cmdtype: 'trackchanges',
    //                 status: ''
    //             }
    //         })
    //         let filter = dataChange.filter((item) => item.type === 'deletion' || item.type === 'insertion')
    //         setTrackchangeData(filter)
    //     }
    // }, [trackChangeData])

    const actionTrack = (type, id, item) => {
        props.dispatchTrackAction({ status: type, list: item });
        trackChangeData[id].status = type;
        setTrackchangeData(trackChangeData);
    }

    const handleSelect = (e) => {
        if (e.target.value === 'all') {
            setTrackchangeData(trackChange)
        } else {
            let filter = trackChange.filter((item) => item.role === e.target.value)
            setTrackchangeData(filter)
        }
    }

    const queryScroll = (item) => {
        props.dispatchFoucs(item);
    };

    return (
        <div className="track-changes">
            <div className="slider-header">
                <h5>Track Changes - ({trackChangeData.length})</h5>
                <div style={{ width: '20px' }}>
                    <FaTimes onClick={props.close} />
                </div>
            </div>
            <div className="track-changes-content">
                <div className="filterTop">
                    <label>Filter:</label>
                    <Form.Select aria-label="Default select example" onChange={(e) => handleSelect(e)}>
                        <option value="all">All</option>
                        <option value="Author">Author</option>
                        <option value="Master Copier">Master Copier</option>
                        <option value="Copy Editor">Copy Editor</option>
                    </Form.Select>
                </div>
                {trackChangeData.length !== 0 ? trackChangeData.map((item, i) =>
                    <div className="track-group" key={i} onClick={() => queryScroll(item)}>
                        {item.type === "insertion" ? <label className="status insert">inserted</label> : <label className="status delete">deleted</label>}
                        <h5>{item.role === "Copy Editor" ? item.role : item.Username}
                            <span>{item.Date_Time}</span>
                        </h5>
                        <p><label>Text:</label>{item.text === undefined ? item.decoration.spec.text : item.text}</p>
                        <div className="trackfooter">
                            <button className="accept-btn" onClick={() => actionTrack('Accept', i, item)}><FaCheck /> Accept</button>
                            <button className="reject-btn" onClick={() => actionTrack('Reject', i, item)}><FaTimes /> Reject</button>
                        </div>
                    </div>
                ) : <NodataFound />}
                {/* <div className="track-group">
                    <label className="status delete">deleted</label>
                    <h5>User
                        <span>05/14/2024 2:53pm</span>
                    </h5>
                    <p><label>Text:</label>Changes</p>
                    <div className="trackfooter">
                        <button className="accept-btn"><FaCheck /> Accept</button>
                        <button className="reject-btn"><FaTimes /> Reject</button>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isTrackChange: state.RightSidebar.TrackChanged,
        isDocvalue: state.LayoutReducer.Docvalue,
        isTrackData: state.RightSidebar.TrackChangedData
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchFoucs: (isNumber) => {
            dispatchFoucs(dispatch, isNumber)
        },
        dispatchTrackAction: (trackdata) => {
            dispatchTrackAction(dispatch, trackdata)
        },
        dispatchTrackchanges: (trackvalue) => {
            dispatchTrackchanges(dispatch, trackvalue)
        },
        // dispatchTrackBlob: (track) => {
        //     // dispatchTrackBlob(dispatch, track)
        // }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TrackChanges);
