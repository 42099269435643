import { Focus, AQchange, AQdelete, CommentAction, DELETE_COMMENT, FOCUS_COMMENT, AQData, LayoutData, sidebarCommentAction, commentValueAction, BLOB_COMMENT, ViewDoc, Trackchange_Data, TrackchangeAction, Trackchange_Blob, DocDetailsData, PDFText, EdiToPdf, TextoLayout} from "./action";

// view doc dispatch
export const dispatchView = (dispatch, DocValue) => {
    dispatch({ type: ViewDoc, ViewDoc: DocValue })
}

// doc details
export const dispatchDocDetails = (dispatch, docDetails) => {
    dispatch({ type: DocDetailsData, DocDetailsData: docDetails })
}

//author query dispatch
export const dispatchFoucs = (dispatch, AQueryNumber) => {
    dispatch({ type: Focus, Focus: AQueryNumber })
}
//author query change dispatch
export const dispatchAQchange = (dispatch, Changedata) => {
    dispatch({ type: AQchange, AQchange: Changedata })
}
//author query delete dispatch
export const dispatchAQdelete = (dispatch, Deletedata) => {
    dispatch({ type: AQdelete, AQdelete: Deletedata })
}

//Comment dispatch
export const dispatchComment = (dispatch, commentData) => {
    dispatch({ type: CommentAction, CommentValue: commentData })
}

// comment delete
export const dispatchdelete = (dispatch, deletedComment) => {
    dispatch({ type: DELETE_COMMENT, DeleteValue: deletedComment })
};

// comment focus
export const dispatchfocus = (dispatch, focusComment) => {
    dispatch({ type: FOCUS_COMMENT, FocusValue: focusComment })
}

//Authorquery dispatch
export const dispatchAuthorQuery = (dispatch, QueryValue) => {
    dispatch({ type: AQData, AuthorQuery: QueryValue })
}

//TOC dispatch
export const dispatchLayoutValue = (dispatch, TocValue) => {
    dispatch({ type: LayoutData, tocData: TocValue })
}

// comment sidebar dispatch
export const dispatchCommentSidebar = (dispatch, commentValue) => {
    dispatch({ type: sidebarCommentAction, commentPass: commentValue })
}

// comment value pass
export const dispatchCommentValue = (dispatch, commentValue) => {
    dispatch({ type: commentValueAction, commentValuePass: commentValue })
}

//  blob comment 
export const dispatchBlobcomment = (dispatch, blobvalue) => {
    dispatch({ type: BLOB_COMMENT, BLOB_COMMENT_DATA: blobvalue })
}

// trackchanges
export const dispatchTrackchanges = (dispatch, trackvalue) => {
    dispatch({ type: Trackchange_Data, TrackchangeData: trackvalue })
}

// trackchanges
export const dispatchTrackBlob = (dispatch, trackblob) => {
    dispatch({ type: Trackchange_Blob, TrackchangeBlob: trackblob })
}

// trackchangesAction
export const dispatchTrackAction = (dispatch, trackAction) => {
    dispatch({ type: TrackchangeAction, TrackAction: trackAction })
}

// pdf text 
export const dispatchPdfLine = (dispatch, lineText) => {
    console.log(lineText)
        dispatch({ type: PDFText, PDFText: lineText })
    }

// text from editor to pdf
export const dispatchTextFromEditor = (dispatch, editorText) => {
    console.log(editorText)
    dispatch({ type: EdiToPdf, EdiToPdf: editorText })
}

// export const dispatchEditorTextTolayout = ( dispatch, textToLayout) => {
//     dispatch({ type: TextoLayout, TextoLayout: textToLayout})
// }