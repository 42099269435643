import { ViewDoc, DocDetailsData } from "../action/action";

const initialState = {
    Docvalue: null,
    DocDetails: null
};

const LayoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case ViewDoc:
            return { ...state, Docvalue: action.ViewDoc }
        case DocDetailsData:
            return { ...state, DocDetails: action.DocDetailsData }
        default:
            return state;
    }
}

export default LayoutReducer;