import { ToolbarButton, Toolbardropdown, SymbolModal } from './toolbar-button'
import { redo, undo } from '@codemirror/commands'
import * as commands from './extensions/toolbar/commands.ts'
import { useCallback, useState } from 'react'

const isMac = /Mac/.test(window.navigator?.platform)

export const ToolbarItems = (function ToolbarItems({
  view,
  visual,
  listDepth,
  handleSymbolData,
}) {
  const [modalShow, setModalShow] = useState(false);

  const handleSymbolClick = useCallback((symbol) => {
    handleSymbolData(symbol); // Propagate symbol data up
    setModalShow(false);
  }, [handleSymbolData]);

  const handleRefOptionSelect = useCallback((refCommand) => {
    handleSymbolData(refCommand); // Propagate ref data up
  }, [handleSymbolData]);

  return (
    <>
      <ToolbarButton
        view={view}
        id="toolbar-undo"
        label='Undo Ctrl+Z'
        command={undo}
        icon="undo"
        className="hidden-toolbar"
        shortcut={isMac ? '⌘Z' : 'Ctrl+Z'}
      />
      <ToolbarButton
        view={view}
        id="toolbar-redo"
        label='Redo Ctrl+Y'
        command={redo}
        icon="repeat"
        shortcut={isMac ? '⇧⌘Z' : 'Ctrl+Y'}
        className="hidden-toolbar div-border"
      />
      <ToolbarButton
        view={view}
        id="toolbar-format-bold"
        label='Bold Ctrl+B'
        command={commands.toggleBold}
        icon="bold"
        shortcut={isMac ? '⌘B' : 'Ctrl+B'}
      />
      <ToolbarButton
        view={view}
        id="toolbar-format-italic"
        label='Italic Ctrl+I'
        command={commands.toggleItalic}
        // active={isActive('\\textit')}
        icon="italic"
        shortcut={isMac ? '⌘I' : 'Ctrl+I'}
      />
      <ToolbarButton
        view={view}
        id="toolbar-format-underline"
        label='Underline Ctrl+U'
        command={commands.toggleUnderline}
        icon="underline"
        shortcut={isMac ? '⌘U' : 'Ctrl+U'}
        className="div-border"
      />
      <Toolbardropdown view={view}></Toolbardropdown>
      <ToolbarButton
        view={view}
        id="toolbar-symbols"
        label='Symbols'
        icon="omega"
        handleSymbolClick={handleSymbolClick} // Pass function as prop
        className="symbols-btn hidden-toolbar"
      />
      <ToolbarButton
        view={view}
        id="toolbar-href"
        label='Insert Link'
        command={commands.wrapInHref}
        icon="link"
      />
      <ToolbarButton
        view={view}
        id="toolbar-ref"
        label='Insert Cross Reference'
        icon="tag"
        className="symbols-btn hidden-toolbar"
        // command={commands.insertRef}
        handleRefOptionSelect={handleRefOptionSelect} // Pass function as prop
      />
      <ToolbarButton
        view={view}
        id="toolbar-cite"
        label='Insert Citation'
        command={commands.insertCite}
        icon="book"
      />
      <ToolbarButton
        view={view}
        id="toolbar-bullet-list"
        label='Bullet List'
        command={commands.toggleBulletList}
        icon="list-ul"
        className="hidden-toolbar"
      />
      <ToolbarButton
        view={view}
        id="toolbar-numbered-list"
        label='Numbered List'
        command={commands.toggleNumberedList}
        icon="list-ol"
        className="hidden-toolbar div-border"
      />
      <ToolbarButton
        view={view}
        id="toolbar-format-indent-decrease"
        label='Decrease Indent Ctrl+['
        command={commands.indentDecrease}
        icon="outdent"
        shortcut={visual ? (isMac ? '⌘[' : 'Ctrl+[') : undefined}
        disabled={listDepth < 2}
         className="hidden-toolbar"
      />
      <ToolbarButton
        view={view}
        id="toolbar-format-indent-increase"
        label='Increase Indent Ctrl+]'
        command={commands.indentIncrease}
        icon="indent"
        shortcut={visual ? (isMac ? '⌘]' : 'Ctrl+]') : undefined}
        disabled={listDepth < 1}
        className="hidden-toolbar div-border"
      />
    </>
  )
})
