export default [
  {
    caption: '\\begin{}',
    snippet: '\\begin{$1}',
    meta: 'env',
    score: 7.849662248028187,
  },
  {
    caption: '\\end{}',
    snippet: '\\end{$1}',
    meta: 'env',
    score: 7.847906405228455,
  },
  {
    caption: '\\usepackage[]{}',
    snippet: '\\usepackage[$1]{$2}',
    meta: 'pkg',
    score: 5.427890758130527,
  },
  {
    caption: '\\item',
    snippet: '\\item ',
    meta: 'cmd',
    score: 3.800886892251021,
  },
  {
    caption: '\\item[]',
    snippet: '\\item[$1] ',
    meta: 'cmd',
    score: 3.800886892251021,
  },
  {
    caption: '\\section{}',
    snippet: '\\section{$1}',
    meta: 'cmd',
    score: 3.0952612541683835,
  },
  {
    caption: '\\textbf{}',
    snippet: '\\textbf{$1}',
    meta: 'cmd',
    score: 2.627755982816738,
  },
  {
    caption: '\\cite{}',
    snippet: '\\cite{$1}',
    meta: 'cmd',
    score: 2.341195220791228,
  },
  {
    caption: '\\label{}',
    snippet: '\\label{$1}',
    meta: 'cmd',
    score: 1.897791904799601,
  },
  {
    caption: '\\textit{}',
    snippet: '\\textit{$1}',
    meta: 'cmd',
    score: 1.6842996195493385,
  },
  {
    caption: '\\includegraphics[]{}',
    snippet: '\\includegraphics[$1]{$2}',
    meta: 'cmd',
    score: 1.4595731795525781,
  },
  {
    caption: '\\documentclass[]{}',
    snippet: '\\documentclass[$1]{$2}',
    meta: 'cmd',
    score: 1.4425339817971206,
  },
  {
    caption: '\\documentclass{}',
    snippet: '\\documentclass{$1}',
    meta: 'cmd',
    score: 1.4425339817971206,
  },
  {
    caption: '\\ref{}',
    snippet: '\\ref{$1}',
    meta: 'cross-reference',
    score: 0.014379554883991673,
  },
  {
    caption: '\\frac{}{}',
    snippet: '\\frac{$1}{$2}',
    meta: 'cmd',
    score: 1.4341091141105058,
  },
  {
    caption: '\\subsection{}',
    snippet: '\\subsection{$1}',
    meta: 'cmd',
    score: 1.3890912739512353,
  },
  {
    caption: '\\hline',
    snippet: '\\hline',
    meta: 'cmd',
    score: 1.3209538327406387,
  },
  {
    caption: '\\caption{}',
    snippet: '\\caption{$1}',
    meta: 'cmd',
    score: 1.2569477427490174,
  },
  {
    caption: '\\centering',
    snippet: '\\centering',
    meta: 'cmd',
    score: 1.1642881814937829,
  },
  {
    caption: '\\vspace{}',
    snippet: '\\vspace{$1}',
    meta: 'cmd',
    score: 0.9533807826673939,
  },
  {
    caption: '\\title{}',
    snippet: '\\title{$1}',
    meta: 'cmd',
    score: 0.9202908262245683,
  },
  {
    caption: '\\author{}',
    snippet: '\\author{$1}',
    meta: 'cmd',
    score: 0.8973590434087177,
  },
  {
    caption: '\\author[]{}',
    snippet: '\\author[$1]{$2}',
    meta: 'cmd',
    score: 0.8973590434087177,
  },
  {
    caption: '\\maketitle',
    snippet: '\\maketitle',
    meta: 'cmd',
    score: 0.7504160124360846,
  },
  {
    caption: '\\textwidth',
    snippet: '\\textwidth',
    meta: 'cmd',
    score: 0.7355328080889112,
  },
  {
    caption: '\\newcommand{}{}',
    snippet: '\\newcommand{$1}{$2}',
    meta: 'cmd',
    score: 0.7264891987129375,
  },
  {
    caption: '\\newcommand{}[]{}',
    snippet: '\\newcommand{$1}[$2]{$3}',
    meta: 'cmd',
    score: 0.7264891987129375,
  },
  {
    caption: '\\date{}',
    snippet: '\\date{$1}',
    meta: 'cmd',
    score: 0.7225518453076786,
  },
  {
    caption: '\\emph{}',
    snippet: '\\emph{$1}',
    meta: 'cmd',
    score: 0.7060308784832261,
  },
  {
    caption: '\\textsc{}',
    snippet: '\\textsc{$1}',
    meta: 'cmd',
    score: 0.6926466355384758,
  },
  {
    caption: '\\multicolumn{}{}{}',
    snippet: '\\multicolumn{$1}{$2}{$3}',
    meta: 'cmd',
    score: 0.5473606021405326,
  },
  {
    caption: '\\input{}',
    snippet: '\\input{$1}',
    meta: 'cmd',
    score: 0.4966021927742672,
  },
  {
    caption: '\\alpha',
    snippet: '\\alpha',
    meta: 'cmd',
    score: 0.49520006391384913,
  },
  {
    caption: '\\in',
    snippet: '\\in',
    meta: 'cmd',
    score: 0.4716039670146658,
  },
  {
    caption: '\\mathbf{}',
    snippet: '\\mathbf{$1}',
    meta: 'cmd',
    score: 0.4682018419466319,
  },
  {
    caption: '\\right',
    snippet: '\\right',
    meta: 'cmd',
    score: 0.4299239459457309,
  },
  {
    caption: '\\left',
    snippet: '\\left',
    meta: 'cmd',
    score: 0.42937815279867964,
  },
  {
    caption: '\\sum',
    snippet: '\\sum',
    meta: 'cmd',
    score: 0.42607994509619934,
  },
  {
    caption: '\\chapter{}',
    snippet: '\\chapter{$1}',
    meta: 'cmd',
    score: 0.422097569591803,
  },
  {
    caption: '\\par',
    snippet: '\\par',
    meta: 'cmd',
    score: 0.413853376001159,
  },
  {
    caption: '\\lambda',
    snippet: '\\lambda',
    meta: 'cmd',
    score: 0.39389600578684125,
  },
  {
    caption: '\\subsubsection{}',
    snippet: '\\subsubsection{$1}',
    meta: 'cmd',
    score: 0.3727781330132016,
  },
  {
    caption: '\\bibitem{}',
    snippet: '\\bibitem{$1}',
    meta: 'cmd',
    score: 0.3689547570562042,
  },
  {
    caption: '\\bibitem[]{}',
    snippet: '\\bibitem[$1]{$2}',
    meta: 'cmd',
    score: 0.3689547570562042,
  },
  {
    caption: '\\text{}',
    snippet: '\\text{$1}',
    meta: 'cmd',
    score: 0.3608680734736821,
  },
  {
    caption: '\\setlength{}{}',
    snippet: '\\setlength{$1}{$2}',
    meta: 'cmd',
    score: 0.354445763583904,
  },
  {
    caption: '\\mathcal{}',
    snippet: '\\mathcal{$1}',
    meta: 'cmd',
    score: 0.35084018920966636,
  },
  {
    caption: '\\newpage',
    snippet: '\\newpage',
    meta: 'cmd',
    score: 0.3277033727934986,
  },
  {
    caption: '\\renewcommand{}{}',
    snippet: '\\renewcommand{$1}{$2}',
    meta: 'cmd',
    score: 0.3267437011085663,
  },
  {
    caption: '\\theta',
    snippet: '\\theta',
    meta: 'cmd',
    score: 0.3210417159232142,
  },
  {
    caption: '\\hspace{}',
    snippet: '\\hspace{$1}',
    meta: 'cmd',
    score: 0.3147206476372336,
  },
  {
    caption: '\\beta',
    snippet: '\\beta',
    meta: 'cmd',
    score: 0.3061799530337638,
  },
  {
    caption: '\\texttt{}',
    snippet: '\\texttt{$1}',
    meta: 'cmd',
    score: 0.3019066753744355,
  },
  {
    caption: '\\times',
    snippet: '\\times',
    meta: 'cmd',
    score: 0.2957960629411553,
  },
  {
    caption: '\\color{}',
    snippet: '\\color{$1}',
    meta: 'cmd',
    score: 0.2864294797053033,
  },
  {
    caption: '\\mu',
    snippet: '\\mu',
    meta: 'cmd',
    score: 0.27635652476799255,
  },
  {
    caption: '\\bibliography{}',
    snippet: '\\bibliography{$1}',
    meta: 'cmd',
    score: 0.2659628337907604,
  },
  {
    caption: '\\linewidth',
    snippet: '\\linewidth',
    meta: 'cmd',
    score: 0.2639498312518439,
  },
  {
    caption: '\\delta',
    snippet: '\\delta',
    meta: 'cmd',
    score: 0.2620578600722735,
  },
  {
    caption: '\\sigma',
    snippet: '\\sigma',
    meta: 'cmd',
    score: 0.25940147926344487,
  },
  {
    caption: '\\pi',
    snippet: '\\pi',
    meta: 'cmd',
    score: 0.25920934567729714,
  },
  {
    caption: '\\hat{}',
    snippet: '\\hat{$1}',
    meta: 'cmd',
    score: 0.25264309033778715,
  },
  {
    caption: '\\bibliographystyle{}',
    snippet: '\\bibliographystyle{$1}',
    meta: 'cmd',
    score: 0.25122317941387773,
  },
  {
    caption: '\\small',
    snippet: '\\small',
    meta: 'cmd',
    score: 0.2447632045426295,
  },
  {
    caption: '\\LaTeX',
    snippet: '\\LaTeX',
    meta: 'cmd',
    score: 0.2334089308452787,
  },
  {
    caption: '\\cdot',
    snippet: '\\cdot',
    meta: 'cmd',
    score: 0.23029085545522762,
  },
  {
    caption: '\\footnote{}',
    snippet: '\\footnote{$1}',
    meta: 'cmd',
    score: 0.2253056071787701,
  },
  {
    caption: '\\newtheorem{}{}',
    snippet: '\\newtheorem{$1}{$2}',
    meta: 'cmd',
    score: 0.215689795055434,
  },
  {
    caption: '\\Delta',
    snippet: '\\Delta',
    meta: 'cmd',
    score: 0.21386475063892618,
  },
  {
    caption: '\\tau',
    snippet: '\\tau',
    meta: 'cmd',
    score: 0.21236188205859796,
  },
  {
    caption: '\\hfill',
    snippet: '\\hfill',
    meta: 'cmd',
    score: 0.2058248088519886,
  },
  {
    caption: '\\leq',
    snippet: '\\leq',
    meta: 'cmd',
    score: 0.20498894440637172,
  },
  {
    caption: '\\footnotesize',
    snippet: '\\footnotesize',
    meta: 'cmd',
    score: 0.2038592081252624,
  },
  {
    caption: '\\large',
    snippet: '\\large',
    meta: 'cmd',
    score: 0.20377416734108866,
  },
  {
    caption: '\\sqrt{}',
    snippet: '\\sqrt{$1}',
    meta: 'cmd',
    score: 0.20240160977404634,
  },
  {
    caption: '\\epsilon',
    snippet: '\\epsilon',
    meta: 'cmd',
    score: 0.2005136761359043,
  },
  {
    caption: '\\Large',
    snippet: '\\Large',
    meta: 'cmd',
    score: 0.1987771081149759,
  },
  {
    caption: '\\rho',
    snippet: '\\rho',
    meta: 'cmd',
    score: 0.1959287380541684,
  },
  {
    caption: '\\omega',
    snippet: '\\omega',
    meta: 'cmd',
    score: 0.19326783415115262,
  },
  {
    caption: '\\mathrm{}',
    snippet: '\\mathrm{$1}',
    meta: 'cmd',
    score: 0.19117752976172653,
  },
  {
    caption: '\\boldsymbol{}',
    snippet: '\\boldsymbol{$1}',
    meta: 'cmd',
    score: 0.18137737738638837,
  },
  {
    caption: '\\gamma',
    snippet: '\\gamma',
    meta: 'cmd',
    score: 0.17940276535431304,
  },
  {
    caption: '\\clearpage',
    snippet: '\\clearpage',
    meta: 'cmd',
    score: 0.1789117552185788,
  },
  {
    caption: '\\infty',
    snippet: '\\infty',
    meta: 'cmd',
    score: 0.17837290019711305,
  },
  {
    caption: '\\phi',
    snippet: '\\phi',
    meta: 'cmd',
    score: 0.17405809173097808,
  },
  {
    caption: '\\partial',
    snippet: '\\partial',
    meta: 'cmd',
    score: 0.17168102367966637,
  },
  {
    caption: '\\include{}',
    snippet: '\\include{$1}',
    meta: 'cmd',
    score: 0.1547080054979312,
  },
  {
    caption: '\\address{}',
    snippet: '\\address{$1}',
    meta: 'cmd',
    score: 0.1525055392611109,
  },
  {
    caption: '\\quad',
    snippet: '\\quad',
    meta: 'cmd',
    score: 0.15242755832392743,
  },
  {
    caption: '\\paragraph{}',
    snippet: '\\paragraph{$1}',
    meta: 'cmd',
    score: 0.152074250347974,
  },
  {
    caption: '\\varepsilon',
    snippet: '\\varepsilon',
    meta: 'cmd',
    score: 0.05411564201390573,
  },
  {
    caption: '\\zeta',
    snippet: '\\zeta',
    meta: 'cmd',
    score: 0.023330249803752954,
  },
  {
    caption: '\\eta',
    snippet: '\\eta',
    meta: 'cmd',
    score: 0.11088718379889091,
  },
  {
    caption: '\\vartheta',
    snippet: '\\vartheta',
    meta: 'cmd',
    score: 0.0025822992078068712,
  },
  {
    caption: '\\iota',
    snippet: '\\iota',
    meta: 'cmd',
    score: 0.0024774003791525486,
  },
  {
    caption: '\\kappa',
    snippet: '\\kappa',
    meta: 'cmd',
    score: 0.04887876299369008,
  },
  {
    caption: '\\nu',
    snippet: '\\nu',
    meta: 'cmd',
    score: 0.09206962821059342,
  },
  {
    caption: '\\xi',
    snippet: '\\xi',
    meta: 'cmd',
    score: 0.06496042899265699,
  },
  {
    caption: '\\varpi',
    snippet: '\\varpi',
    meta: 'cmd',
    score: 0.0007039358167790341,
  },
  {
    caption: '\\varrho',
    snippet: '\\varrho',
    meta: 'cmd',
    score: 0.0011279491613898612,
  },
  {
    caption: '\\varsigma',
    snippet: '\\varsigma',
    meta: 'cmd',
    score: 0.0010424880711234978,
  },
  {
    caption: '\\upsilon',
    snippet: '\\upsilon',
    meta: 'cmd',
    score: 0.00420715572598688,
  },
  {
    caption: '\\varphi',
    snippet: '\\varphi',
    meta: 'cmd',
    score: 0.03351251516668212,
  },
  {
    caption: '\\chi',
    snippet: '\\chi',
    meta: 'cmd',
    score: 0.043373492287805675,
  },
  {
    caption: '\\psi',
    snippet: '\\psi',
    meta: 'cmd',
    score: 0.09994508706163642,
  },
  {
    caption: '\\Gamma',
    snippet: '\\Gamma',
    meta: 'cmd',
    score: 0.04801549269801977,
  },
  {
    caption: '\\Theta',
    snippet: '\\Theta',
    meta: 'cmd',
    score: 0.038090902146599444,
  },
  {
    caption: '\\Lambda',
    snippet: '\\Lambda',
    meta: 'cmd',
    score: 0.032206594305977686,
  },
  {
    caption: '\\Xi',
    snippet: '\\Xi',
    meta: 'cmd',
    score: 0.01060997225400494,
  },
  {
    caption: '\\Pi',
    snippet: '\\Pi',
    meta: 'cmd',
    score: 0.021264671817473237,
  },
  {
    caption: '\\Sigma',
    snippet: '\\Sigma',
    meta: 'cmd',
    score: 0.05769642802079917,
  },
  {
    caption: '\\Upsilon',
    snippet: '\\Upsilon',
    meta: 'cmd',
    score: 0.00032875192955749566,
  },
  {
    caption: '\\Phi',
    snippet: '\\Phi',
    meta: 'cmd',
    score: 0.0538724950042562,
  },
  {
    caption: '\\Psi',
    snippet: '\\Psi',
    meta: 'cmd',
    score: 0.03056589143021648,
  },
  {
    caption: '\\Omega',
    snippet: '\\Omega',
    meta: 'cmd',
    score: 0.09490387997853639,
  },
]
