import { useEffect, useRef, useState, useCallback } from "react";
import { pdfjs } from 'react-pdf';
// import ReactDOM from 'react-dom'; // Import ReactDOM
import { createRoot } from 'react-dom/client';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { BiRefresh } from "react-icons/bi";
import axios from 'axios';
import { toastError, toastSuccess, toastWarning } from '../toaster';
import pathconfig from '../../pathconfig.json';
import { FaCheckCircle, FaExclamationCircle, FaSearchMinus, FaSearchPlus, FaCode, FaDownload } from "react-icons/fa";
import { Modal, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { FaFloppyDisk } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import TextLayerBuilder from './TextLayerBuilder'; // Import the TextLayerBuilder component
import { dispatchPdfLine } from "../../redux/action";
import packjson from '../../../package.json';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfEditor = (props) => {
    const [fileContent, setFileContent] = useState('');
    const [currentFile, setCurrentFile] = useState(null);
    const [compileAction, setCompileAction] = useState(false);
    const [showPdf, setShowpdf] = useState(false);
    const [pdfData, setPdfdata] = useState(null)
    const [pdfDoc, setPdfDoc] = useState(null);
    // const [logContent, setLogContent] = useState('');
    // const [base64logContent, setBase64LogContent] = useState('');
    const [pageNumbers, setPageNumbers] = useState([]);
    const [pdfStatus, setPdfStatus] = useState('')
    const containerRef = useRef(null);
    const saveButton = useRef(null);
    const CompileButton = useRef(null);
    const pagesRef = useRef([]);
    const [zoomLevel, setZoomLevel] = useState(100);
    const [time, setTime] = useState(0);
    const [timeComplete, setTimeComplete] = useState(false)
    const [timer, setTimer] = useState(false);
    const location = useLocation();
    const [downloadPercentage, setDownloadPercentage] = useState(0);
    const [downloadBar, setDownloadBar] = useState(false);
    const [showconfirm, setConfirm] = useState(false)
    const [showProgress, setShowprogress] = useState(false);
    const [trackValue, setTrackvalue] = useState([])
    const [trackSpreate, setTrackSpreate] = useState({});
    const [userdetails, setUserdetails] = useState({})
    const [submitData, setSubmitData] = useState(false);
    const [progressDetails, setProgressDetails] = useState('Please wait file is progess to submit.');
    const [saveStatus, setSaveStatus] = useState(false)
    const [trackContent, setTrackContent] = useState('')
    // const [CompileCode, setCompileCode] = useState(false)
    const navigate = useNavigate();
    const [textContents, setTextContents] = useState();
    let CompileCode = true;
    let CompileCheck = true;

    const compileCode = async (value) => {
        if (CompileCheck) {
            let validationItem = await validationcheck('compilercheck');
            if (validationItem.length > 0) {
                return;
            }
        }
        setCompileAction(false);
        setTimer(true);
        setTime(0);
        setCompileAction(true);
        setTimeComplete(false);
        setProgressDetails(!CompileCode ? 'Uncited Element Found. Please wait Re-compiler in progress.' : 'Please wait compiler in progress.');
        setShowprogress(true);
        if (value === 'submitHandle') {
            setProgressDetails('Please wait auto compiler in progress.');
            setShowprogress(true);
        }
        try {
            if (!fileContent || !currentFile) {
                toastError(`Cannot able to fetch the file for compiling. Please contact journal manager`);
                setCompileAction(false);
                return;
            }
            // let tagInsertion = await trackchangeTagInsertion(fileContent, props.trackData)
            const docId = props.isDocDetails.docid;
            let data = {
                data: fileContent,
                filename: docId,
                is_Submit: value === 'submitHandle' ? true : false,
                role: userdetails.role
            }
            const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].compiler_api}`;
            const response = await axios.post(url, data);
            // If the code reaches here, it means the compile was successful
            try {
                const pdfResponse = await blobUpload(response.data);
                //setPdfdata(response.data.pdfBase64);
                props.LogDataFile(true)
                setPdfdata(response.data.pdfBase64);
                let logContent = atob(response.data.logBase64);
                let checkText = 'LaTeX Warning: There were undefined references';
                if (CompileCode) {
                    if (logContent.includes(checkText)) {
                        setProgressDetails('Uncited Element Found. Please wait Re-compiler in progress.');
                        // setCompileCode(true)
                        CompileCheck = false;
                        CompileCode = false;
                        setShowprogress(true);
                        await compileCode();
                        // CompileCode = false;
                        // setCompileCode(false)
                        setShowprogress(false);
                    }
                }
                // setBase64LogContent(response.data.logBase64)
                if (response.data.errors.length === 0) {
                    setPdfStatus('success');
                    if (value === 'submitHandle') {
                        setSubmitData(true);
                        setProgressDetails('Please wait file is progess to submit.');
                        setShowprogress(false);
                    } else {
                        toastSuccess(`Compilation completed Successfully.`);
                        setShowprogress(false)
                    }
                    setShowpdf(true);
                    setTimeComplete(true);
                    setPdfStatus('');
                }
                else if (response.data.errors.filter(item => item.type == 'Error').length == 0) {
                    if (response.data && response.data.errors && response.data.errors) {
                        if (Array.isArray(response.data.errors) || typeof response.data.errors === 'object') {
                            props.compileError(response.data.errors);
                        }
                    }
                    if (value === 'submitHandle') {
                        setSubmitData(true);
                        setProgressDetails('Please wait file is progess to submit.');
                        setShowprogress(false)
                    } else {
                        setPdfStatus('error');
                        toastSuccess(`The file has been successfully compiled with warnings.`);
                        setShowprogress(false);
                        CompileCode = true;
                    }
                    setShowpdf(true);
                    setTimeComplete(true);
                    setPdfStatus('');
                }
                else {
                    if (response.data && response.data.errors && response.data.errors) {
                        if (Array.isArray(response.data.errors) || typeof response.data.errors === 'object') {
                            props.compileError(response.data.errors);
                        }
                    }
                    setPdfStatus('error');
                    toastSuccess(`The file has been successfully compiled with Errors.`);
                    setCompileAction(false);
                    setShowprogress(false);
                    CompileCode = true;
                    setPdfStatus('');
                    if (value === 'submitHandle') {
                        setProgressDetails('Please wait file is progess to submit.');
                        setShowprogress(false)
                    }
                }
            } catch (error) {
                // Handle the error from blobupload here
                toastError(`upload error - ${error.message}`);
                throw new Error(`upload error - ${error.message}`);
            }

        } catch (error) {
            if (error.response && error.response.data && error.response.data.details) {
                if (Array.isArray(error.response.data.details) || typeof error.response.data.details === 'object') {
                    props.compileError(error.response.data.details);
                }
            }
            toastError(`Error compiling code - ${error.message}`);
            setPdfStatus('error');
            setCompileAction(false);
            setShowprogress(false);
            setPdfStatus('');
        }
    };

    useEffect(() => {
        if (downloadBar === true) {
            let percentage = 0;
            const interval = setInterval(() => {
                percentage++;
                setDownloadPercentage(percentage);
                if (percentage >= 100) {
                    clearInterval(interval);
                }
            }, 50);
        }
    }, [downloadBar])


    // useEffect(() => {
    //     let logContent = atob(props.isDocDetails?.logContent);
    //     let checkText = 'LaTeX Warning: There were undefined references';
    //     if (localStorage.getItem(`${props.isDocDetails.docid}_logstatus`) === null) {
    //         if (logContent.includes(checkText)) {
    //             setTimeout(() => {
    //                 if (CompileButton.current) {
    //                     CompileButton.current.click();
    //                     setCompileCode(true)
    //                 }                   
    //                 localStorage.setItem(`${props.isDocDetails.docid}_logstatus`, true);
    //             }, 3000);

    //         }
    //     }
    // }, [props.isDocDetails]);

    useEffect(() => {
        if (props.trackData) {
            setTrackvalue(props.trackData);

            const filterById = (id) => {
                const { isAuthorQuery, isComment, fileItems } = props;

                const AuthorQuery = isAuthorQuery?.length ? isAuthorQuery.filter(item => item.ActivityID === id) : [];
                const TrackValue = trackValue?.filter(item => item.ActivityID === id) || [];
                const Comment = isComment?.comment?.filter(item => item.ActivityID === id) || [];
                const Attachement = fileItems?.filter(item => item.aty_id === id) || [];

                return {
                    AuthorQuery,
                    TrackValue,
                    Comment,
                    Attachement
                };
            };

            const filteredResults = filterById(props.isDocDetails?.ActivityID);
            console.log(filteredResults);

            let deletion = filteredResults.TrackValue?.filter((item) => item.type === 'deletion') || [];
            let insertion = filteredResults.TrackValue?.filter((item) => item.type === 'insertion') || [];

            setTrackSpreate({
                'Comment': filteredResults.Comment?.length || 0,
                'AuthorQuery': filteredResults.AuthorQuery?.length || 0,
                'deletion': deletion?.length || 0,
                'insertion': insertion?.length || 0,
                'Attachement': filteredResults.Attachement?.length || 0
            });
        }
    }, [props.trackData]);


    useEffect(() => {
        if (props.downloadTrigger === true) {
            pdfDownlad();
        }
    }, [props.downloadTrigger])

    useEffect(() => {
        if (props.userProfile) {
            setUserdetails(props.userProfile);
        }
        if (props.editorStyleValue) {
            setFileContent(props.editorStyleValue);
        }
    }, [props.userProfile, props.editorStyleValue])

    const onSave = useCallback(async () => {
        props.fileSave(true);
        setSaveStatus(false)
        try {
            if (!fileContent || !currentFile) {
                toastError(`Cannot able to fetch the file for compiling. Please contact journal manager`)
                return;
            }
            const docId = props.isDocDetails.docid;
            let GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
            let GetDetailData = JSON.parse(GetDetailDataString);
            let authorquerys = props.isAuthorQuery;
            let comments = props.isComment;
            let blobcomments = props.isBlobComment;
            let trackchange = props.trackData;
            let data = {
                htmlcontent: fileContent,
                QueryContent: JSON.stringify(authorquerys),
                CommentContent: JSON.stringify(comments),
                BlobComment: JSON.stringify(blobcomments),
                trackChanges: JSON.stringify(trackchange),
                istexfile: true,
                docid: docId,
                activityID: GetDetailData.ActivtyDetails.ActivityID,
                isAutoSave: false,
                ArticleGUID: GetDetailData.Articledetails.ArticleGUID,
            }
            const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].save_api}`;
            await axios.post(url, data)
                .then(({ data }) => {
                    props.fileSave(false);
                    props.fileSaveStatus(true);
                    setSaveStatus(data.is_success === false ? true : true)
                    //toastSuccess(`Saved Successfully.`)
                })
                .catch(error => {
                    props.fileSave(false);
                    toastError(`Error compiling code - ${error.message}`);
                });
        } catch (error) {
            toastError(`Error compiling code - ${error.message}`);
        }
    }, [fileContent, currentFile, location.search, props]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                onSave();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onSave]);

    async function blobUpload(responseData) {
        try {
            const docId = props.isDocDetails.docid;
            const GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
            const GetDetailData = JSON.parse(GetDetailDataString);
            const outPath = GetDetailData.Articledetails.OutputPath.substring(0, GetDetailData.Articledetails.OutputPath.lastIndexOf('/'));
            const filename = GetDetailData.Articledetails.ArticleName;
            const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobPDFupload}`;
            const data = {
                docid: docId,
                outPath: outPath,
                filename: filename
            }
            // const url = "http://localhost:5000/latexbackend/bloboperation/blobpdfupload";
            // const uploads = [];
            // if (responseData.pdfBase64) {
            //     uploads.push({
            //         base64Data: responseData.pdfBase64,
            //         filepath: GetDetailData.Articledetails.OutputPath.replace('.xml', '.pdf'),
            //         contentType: 'application/pdf'
            //     });
            // }
            // if (responseData.logBase64) {
            //     uploads.push({
            //         base64Data: responseData.logBase64,
            //         filepath: GetDetailData.Articledetails.OutputPath.replace('.xml', '.log'),
            //         contentType: 'text/plain'
            //     });
            // }
            // if (responseData.zipBase64) {
            //     uploads.push({
            //         base64Data: responseData.zipBase64,
            //         filepath: GetDetailData.Articledetails.OutputPath.replace('.xml', '.zip'),
            //         contentType: 'application/zip'
            //     });
            // }
            // if (responseData.pgInfoBase64) {
            //     uploads.push({
            //         base64Data: responseData.pgInfoBase64,
            //         filepath: GetDetailData.Articledetails.OutputPath.replace('.xml', '.pgInfo'),
            //         contentType: 'text/plain'
            //     });
            // }
            // if (responseData.auxBase64) {
            //     uploads.push({
            //         base64Data: responseData.auxBase64,
            //         filepath: GetDetailData.Articledetails.OutputPath.replace('.xml', '.aux'),
            //         contentType: 'text/plain'
            //     });
            // }
            // if (responseData.othersBase64) {
            //     uploads.push({
            //         base64Data: responseData.othersBase64,
            //         filepath: GetDetailData.Articledetails.OutputPath.substring(0, GetDetailData.Articledetails.OutputPath.lastIndexOf('/')),
            //         contentType: 'application/x-directory'
            //     });
            // }

            const response = await axios.post(url, data);
            console.log(response.data)
            // if (response.data.length) {
            //     console.log(response.data)
            //     toastSuccess(`Files saved successfully.`);
            // };
            return response;
        } catch (error) {
            // throw new Error(`Upload error - ${error.message}`);
            console.log(`Upload error - ${error.message}`);
        }
    }

    async function trackchangeTagInsertion(fileContent, trackvalue, commentsValue, value) {
        try {
            let latexContent = fileContent;
            // latexContent = latexContent.replace(/(\r\n)+/ig, '\r\n');
            // latexContent = latexContent.replace(/(\n)+/ig, '\n');
            // latexContent = latexContent.replace(/^\t/img, '');
            let ranges = [...(trackvalue || []), ...(commentsValue || [])];
            let trachranges = ranges.sort((a, b) => {
                if (a.from === b.from) {
                    return b.to - a.to;
                }
                return b.from - a.from;
            });
            const extractDeletionText = (decoration) => {
                return decoration?.widget?.text ||
                    decoration?.spec?.widget?.text ||
                    decoration?.spec?.text ||
                    '';
            }
            const insertText = (originalText, range, type) => {
                let before = originalText.slice(0, range.from);
                let after = originalText.slice(range.to);
                if (value === true) {
                    if (type === 'cmt') {
                        return before + `\\cmt[${JSON.stringify(range).replace(/\\"/g, 'itempslash"').replace(/_/g, '\\_').replace(/\$/g, '\\$').replace(/%/g, '\\%').replace(/#/g, '\\#').replace(/\[/g, 'iosb').replace(/\]/g, 'icsb').replace(/\{/g, 'iocb').replace(/\}/g, 'iccb')}]{` + range.selectText + "}" + after;
                    }
                    else if (type === 'del')
                        return before + `\\del[${JSON.stringify(range).replace(/\\"/g, 'itempslash"').replace(/_/g, '\\_').replace(/\$/g, '\\$').replace(/%/g, '\\%').replace(/#/g, '\\#').replace(/\[/g, 'iosb').replace(/\]/g, 'icsb').replace(/\{/g, 'iocb').replace(/\}/g, 'iccb')}]{` + range.decoration.spec.text + "}" + after;
                    else {
                        return before + `\\ins[${JSON.stringify(range).replace(/\\"/g, 'itempslash"').replace(/_/g, '\\_').replace(/\$/g, '\\$').replace(/%/g, '\\%').replace(/#/g, '\\#').replace(/\[/g, 'iosb').replace(/\]/g, 'icsb').replace(/\{/g, 'iocb').replace(/\}/g, 'iccb')}]{` + range.text + "}" + after;
                    }
                }
                else {
                    if (type === 'del')
                        return before + "\\del{" + range.decoration.spec.text + "}" + after;
                    else {
                        return before + "\\ins{" + range.text + "}" + after;
                    }
                }
            }
            for (let i = 0; i < trachranges.length; i++) {
                if (trachranges[i].cmdtype === 'Comments') {
                    latexContent = insertText(latexContent, trachranges[i], 'cmt');
                }
                else if (trachranges[i].type === 'insertion') {
                    latexContent = insertText(latexContent, trachranges[i], 'ins');
                }
                else {
                    latexContent = insertText(latexContent, trachranges[i], 'del');
                }
            }
            console.log(latexContent);
            return latexContent;
        } catch (error) {
            throw new Error(`trackchange tag insertion error - ${error.message}`);
        }
    }

    useEffect(() => {
        if (submitData) {
            const submitFun = async () => {
                setShowprogress(true)
                try {
                    const docId = props.isDocDetails.docid;
                    let GetDetailDataString = localStorage.getItem(`${docId}_init-getdetails`);
                    let GetDetailData = JSON.parse(GetDetailDataString);
                    const OutputPath = GetDetailData.Articledetails.OutputPath;
                    const ActivityName = GetDetailData.ActivtyDetails.ActivityName;
                    const lastSlashIndex = OutputPath.lastIndexOf('/');
                    const OutputdirectoryPath = OutputPath.substring(0, lastSlashIndex);
                    const OutputPathfileName = OutputPath.substring(lastSlashIndex + 1);
                    let pageInfoData = await fetchPageInfoData(OutputPath);
                    let totalPage = extractTotalPage(pageInfoData);
                    let authorquerys = props.isAuthorQuery;
                    let comments = props.isComment;
                    let blobcomments = props.isBlobComment;
                    let trackchange = props.trackData;
                    let tagInsertion = ["L2 QA", "Master Copier"].includes(ActivityName) ? await trackchangeTagInsertion(fileContent, props.trackData, props.isComment.comment, submitData) : fileContent;
                    let data = {
                        htmlcontent: fileContent,
                        OutputTexContent: tagInsertion,
                        QueryContent: JSON.stringify(authorquerys),
                        CommentContent: JSON.stringify(comments),
                        BlobComment: JSON.stringify(blobcomments),
                        trackChanges: JSON.stringify(trackchange),
                        istexfile: true,
                        docid: docId,
                        getjobDetails: GetDetailData,
                        activityID: GetDetailData.ActivtyDetails.ActivityID,
                        isAutoSave: false,
                        ArticleGUID: GetDetailData.Articledetails.ArticleGUID,
                        filename: currentFile, // Assuming this is the filename to compile
                        editorChangeDetails: { trackchanges: { 'deletion': trackSpreate.deletion, 'insertion': trackSpreate.insertion, 'totalCount': trackSpreate.deletion + trackSpreate.insertion }, comments: trackSpreate.Comment, AuthorQuery: trackSpreate.AuthorQuery, Attachement: trackSpreate.Attachement, TotalPDFPageCount: totalPage },
                        editorChangeFilename: 'ArticleDetails.json',
                        resourcepath: GetDetailData.Articledetails.ArtResourcePath,
                        OutputdirectoryPath: OutputdirectoryPath,
                        OutputPathfileName: OutputPathfileName.replace('.xml', '.tex')
                    };

                    const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].submit_all_api}`; // Backend endpoint to handle all operations
                    const response = await axios.post(url, data);

                    if (response.data.is_success === true) {
                        setShowprogress(false)
                        // toastSuccess(`Submitted Successfully.`);
                        localStorage.removeItem(`${docId}_init-getdetails`);
                        localStorage.removeItem(`${docId}_captchaVerified`);
                        localStorage.removeItem(`${docId}_logstatus`);
                        navigate(`/notification?docid=${docId}`, { state: 'success' })
                    } else {
                        throw new Error('Submit API returned an error.');
                    }
                } catch (error) {
                    setShowprogress(false)
                    toastError(`Error: ${error.message}`);
                }

            }
            submitFun();
        }
    }, [submitData])

    const handleSubmit = async () => {
        setConfirm(false)
        compileCode('submitHandle')
    };

    async function fetchPageInfoData(OutputPath) {
        const fileurl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`;
        const filedata = { InputPath: OutputPath.replace('.xml', '.pgInfo') };
        const isFileexist = await axios.post(fileurl, filedata);

        if (isFileexist.data.exist) {
            const response = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_preview_file}`, filedata);
            return response.data;
        }
        return '';
    }

    function extractTotalPage(pageInfoData) {
        const totalPageMatch = pageInfoData.match(/<TotalPage>(\d+)<\/TotalPage>/);
        return totalPageMatch ? parseInt(totalPageMatch[1], 10) : 0;
    }

    useEffect(() => {
        if (props.pdfData) {
            setCurrentFile(props.pdfData.Filename)
            if (props.valueChange.length === 0) {
                setFileContent(props.pdfData.Content);
                if (props.pdfData.pdfContent !== '{}') {
                    setPdfdata(props.pdfData.pdfContent);
                    setShowpdf(true);
                }
                if (props.userProfile?.role === 'Author') {
                    setTrackContent(props.pdfData.Content);
                }
            }
        }
        if (props.valueChange) {
            setFileContent(props.valueChange);
        }
        if (props.pdfShow) {
            setShowpdf(props.pdfShow)
        }
    }, [props.pdfData, props.pdfShow, props.valueChange])

    useEffect(() => {
        if (trackContent.length !== 0) {
            function processHTMLString(htmlString) {
                const trackChanges = [];
                const comments = [];
                const regex = /\\(ins|del|cmt)\[([\s\S]*?)\]\{((?:[^{}]*|\{(?:[^{}]*|\{[^{}]*\})*\})*)\}/g;
                let match;
                htmlString = htmlString.replace(/\r\n/g, '\n');
                while ((match = regex.exec(htmlString)) !== null) {
                    let [fullMatch, command, jsonString, content] = match;
                    const valueObj = JSON.parse(jsonString.replace(/itempslash"/g, '\\"').replace(/\\_/g, '_').replace(/\\\$/g, '$').replace(/\\%/g, '%').replace(/\\#/g, '#').replace(/iosb/g, '[').replace(/icsb/g, ']').replace(/iocb/g, '{').replace(/iccb/g, '}'));
                    // content = content.replace(/iosb/g, '[').replace(/icsb/g, ']').replace(/iocb/g, '{').replace(/iccb/g, '}');
                    const fromPosition = match.index;
                    valueObj.from = fromPosition;
                    let toPosition;
                    if (command === 'del') {
                        htmlString = htmlString.slice(0, fromPosition) + htmlString.slice(fromPosition + fullMatch.length);
                        toPosition = fromPosition;
                        valueObj.to = toPosition;
                        trackChanges.push(valueObj);
                    } else if (command === 'ins') {
                        htmlString = htmlString.slice(0, fromPosition) + content + htmlString.slice(fromPosition + fullMatch.length);
                        toPosition = fromPosition + content.length;
                        valueObj.to = toPosition;
                        trackChanges.push(valueObj);
                    } else if (command === 'cmt') {
                        htmlString = htmlString.slice(0, fromPosition) + content + htmlString.slice(fromPosition + fullMatch.length);
                        toPosition = fromPosition + content.length;
                        valueObj.to = toPosition;
                        comments.push(valueObj);
                    }
                    regex.lastIndex = fromPosition + (content ? content.length : 0);
                }
                return { modifiedHTML: htmlString, trackChanges, comments };
            }
            const result = processHTMLString(trackContent);
            console.log(result);
            setTimeout(() => {
                setFileContent(result.modifiedHTML);
            }, 1000);
        }
    }, [trackContent]);

    useEffect(() => {
        if (compileAction === true) {
            let Timer = null;
            if (timeComplete === false) {
                Timer = setInterval(() => {
                    setTime((prevState) => prevState + 1);
                }, 1000);
            } else {
                clearInterval(Timer);
            }
            return () => {
                clearInterval(Timer);
            };
        }
    }, [compileAction, timeComplete]);

    useEffect(() => {
        if (pdfData) {
            const byteCharacters = atob(pdfData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);

            const loadingTask = pdfjs.getDocument(url);
            loadingTask.promise.then(async (pdf) => {
                setPdfDoc(pdf);
                const pages = Array.from({ length: pdf.numPages }, (_, i) => i + 1);
                setPageNumbers(pages);

                // Extract text content for each page
                const textContents = await Promise.all(
                    pages.map(async (pageNum) => {
                        const page = await pdf.getPage(pageNum);
                        const textContent = await page.getTextContent();
                        const annotations = await page.getAnnotations();
                        console.log(annotations, "annotations links");
                        const viewport = page.getViewport({ scale: 1 });
                        return { pageNum, textContent, annotations, viewport };
                    })
                );
                setTextContents(textContents);
                setCompileAction(false);
            }, (reason) => {
                console.error('Error loading PDF: ' + reason);
            });

            return () => {
                URL.revokeObjectURL(url);
            };
        }
    }, [pdfData]);


    // useEffect(() => {
    //     if (base64logContent) {
    //       // Decode the Base64 string
    //       const decodedLog = atob(base64logContent);

    //       // Set the decoded string into state
    //       setLogContent(decodedLog);
    //     }
    //   }, [base64logContent]);

    const renderPage = (num) => {
        pdfDoc.getPage(num).then((page) => {
            const scale = 1.5 * zoomLevel / 100;
            const viewport = page.getViewport({ scale });

            // Create and configure the canvas
            const canvas = document.createElement('canvas');
            canvas.className = 'pdf-canvas';
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Create and configure the page container div
            const pageDiv = document.createElement('div');
            pageDiv.className = 'pdf-page';
            pageDiv.style.position = 'relative'; // Ensure correct positioning
            pageDiv.style.width = `${viewport.width}px`;
            pageDiv.style.height = `${viewport.height}px`;
            // pageDiv.style.transform = `scale(${scale})`;
            // pageDiv.style.transformOrigin = 'top left';

            // Add the canvas to the page div
            pageDiv.appendChild(canvas);

            // Render the PDF page onto the canvas
            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            page.render(renderContext).promise.then(() => {
                // Fetch text content and render text layer
                page.getTextContent().then((textContent) => {
                    const textLayerDiv = document.createElement('div');
                    textLayerDiv.className = 'textLayer';
                    textLayerDiv.style.width = `${viewport.width}px`;
                    textLayerDiv.style.height = `${viewport.height}px`;
                    textLayerDiv.style.left = '6px';
                    textLayerDiv.style.top = '1.5px';
                    textLayerDiv.style.position = 'absolute'; // Overlay text on canvas
                    // textLayerDiv.style.transform = `scale(${scale})`;
                    textLayerDiv.style.transformOrigin = 'top left';

                    pageDiv.appendChild(textLayerDiv);

                    // Create a root for the text layer and render the component
                    const root = createRoot(textLayerDiv);
                    root.render(<TextLayerBuilder textContent={textContent} viewport={page.getViewport({ scale: 1.5 * zoomLevel / 100 })} onLineTextClick={handleLineTextClick} />);
                    console.log('Text content:', textContent);

                });
            });

            // Add the page div to the container
            containerRef.current.appendChild(pageDiv);
            pagesRef.current[num - 1] = pageDiv;
        });
    };

    // Callback function to handle clicked line text from TextLayerBuilder
    const handleLineTextClick = (lineText) => {
        props.dispatchPdfLine(lineText)
        console.log('Clicked line text:', lineText);
        // console.log('Text content:', textContents);

    };


    const zoomIn = () => {
        if (zoomLevel < 200) {
            setZoomLevel(zoomLevel + 20); // Increase zoom by 10%
        }
    };

    const zoomOut = () => {
        if (zoomLevel > 40) {
            setZoomLevel(zoomLevel - 20); // Decrease zoom by 10%
        }
    };

    const formatTime = (totalSeconds) => {
        const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
        const seconds = String(totalSeconds % 60).padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    const handleZoomChange = (event) => {
        setZoomLevel(Number(event.target.value));
    };

    useEffect(() => {
        if (pdfDoc) {
            containerRef.current.innerHTML = ''; // Clear the container
            pagesRef.current = [];
            const pages = Array.from({ length: pdfDoc.numPages }, (_, i) => i + 1);
            setPageNumbers(pages);
            pages.forEach(renderPage);
        }
    }, [pdfDoc, zoomLevel]);


    const pdfDownlad = async () => {
        try {
            setDownloadBar(true)
            const docId = props.isDocDetails.docid;
            const linkPath = JSON.parse(localStorage.getItem(`${docId}_init-getdetails`));
            const pdfReplace = linkPath.Articledetails.OutputPath.replace('.xml', '.pdf')
            //const url2 = linkPath.Articledetails.blobDomain + pdfReplace; // Replace with your file URL
            const data = {
                InputPath: pdfReplace,
            }
            const isFileexist = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`, data);
            if (isFileexist.data.exist === true) {
                const response = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].download}`, {
                    blobUrl: pdfReplace,
                    type: 'application/pdf'
                }, {
                    responseType: 'blob',// Important               
                });
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${linkPath.Articledetails.ArticleName}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                setDownloadBar(false)
                toastSuccess(`File Downloaded successfully.`);
            }
            else {
                setDownloadBar(false)
                toastError(`Compilation Required: Please compile the document before downloading the PDF.`)
            }
        } catch (error) {
            console.error('Error downloading file:', error);
            setDownloadBar(false)
            toastError(`File Downloaded Error.`);
        }
    }

    useEffect(() => {
        console.log(props.isBlobComment, 'isBlobComment')
    }, [props.isBlobComment]);


    useEffect(() => {
        if (props.isTextfromEditor && props.isTextfromEditor.entireLine && props.isTextfromEditor.selectedText) {
            const cleanedText = cleanupLatexInput(props.isTextfromEditor.entireLine);
            console.log('Cleaned bibliography entry:', cleanedText);
            highlightAndScrollToText(cleanedText, props.isTextfromEditor.selectedText);
        }
        console.log(props.isTextfromEditor, 'isTextfromEditor');

    }, [props.isTextfromEditor, textContents]);

    // useEffect(() => {
    //     if (props.isTextfromEditor && props.isTextfromEditor.entireLine) {
    //         const cleanedText = cleanupBibliographyEntry(props.isTextfromEditor.entireLine);
    //         console.log('Cleaned bibliography entry:', cleanedText);
    //         highlightAndScrollToText(cleanedText);
    //     }
    //     console.log(props.isTextfromEditor, 'isTextfromEditor');

    // }, [props.isTextfromEditor, textContents]);

    const cleanupLatexInput = (input) => {
        // Handle section commands
        if (input.startsWith('\\section')) {
            return input.match(/\\section{(.*?)}/)?.[1] || '';
        }

        // Handle bibliography entries
        let cleaned = input.replace(/\\[a-z]+{|}|\\/g, '');
        cleaned = cleaned.replace(/\s+/g, ' ');
        cleaned = cleaned.replace(/\s+,/g, ',');
        return cleaned.trim();
    };

    ///////kmknofwnokanvlonvrfoj
    const highlightAndScrollToText = (entireLine, selectedText) => {
        if (!textContents || !pagesRef.current) return;

        const match = entireLine.match(/\{(.*?)\}/);
        const searchText = match ? match[1].replace(/---/g, '—').replace(/--/g, '–') : entireLine.replace(/---/g, '—').replace(/--/g, '–');

        for (let i = 0; i < textContents.length; i++) {
            let pageContent = textContents[i].textContent.items.map(item => item.str).join(' ');
            pageContent = pageContent.replace(/\s\s+/g, ' ').replace(/-\s/g, '');

            if (pageContent.includes(searchText)) {
                let pageDiv = pagesRef.current[i];

                if (pageDiv) {
                    pageDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });

                    setTimeout(() => {
                        const textLayer = pageDiv.querySelector('.textLayer');
                        if (textLayer) {
                            const textElements = textLayer.querySelectorAll('span');
                            let highlightedElement = null;

                            textElements.forEach(element => {
                                if (element.textContent.includes(selectedText)) {
                                    highlightedElement = element;

                                    // Get text element dimensions
                                    const rect = element.getBoundingClientRect();
                                    const textLayerRect = textLayer.getBoundingClientRect();

                                    // Create highlight element with padding
                                    const highlight = document.createElement('div');
                                    const padding = 4; // Adjust padding as needed

                                    Object.assign(highlight.style, {
                                        position: 'absolute',
                                        left: `${rect.left - textLayerRect.left - padding}px`,
                                        top: `${rect.top - textLayerRect.top - padding}px`,
                                        width: `${rect.width + (padding * 2)}px`,
                                        height: `${rect.height + (padding * 3)}px`,
                                        backgroundColor: '#FFFF00',
                                        color: '#000000',
                                        opacity: '0.5',
                                        borderRadius: '2px',
                                        pointerEvents: 'none',
                                        zIndex: '1'
                                    });

                                    textLayer.appendChild(highlight);
                                    element.style.position = 'relative';
                                    element.style.zIndex = '1';

                                    setTimeout(() => {
                                        textLayer.removeChild(highlight);
                                        element.style.position = '';
                                        element.style.zIndex = '';
                                    }, 2000);

                                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                }
                            });
                        }
                    }, 500);
                    break;
                }
            }
        }
    };

    const validationcheck = async (value) => {
        try {
            let validitionlist = [];
            let count = 0;
            if (value !== 'compilercheck') {
                if (userdetails.role === 'Author' || userdetails.role === 'Editor') {
                    let authorquerydata = props.isAuthorQuery;
                    for (let i = 0; i < authorquerydata.length; i++) {
                        if (authorquerydata[i].replies === undefined || authorquerydata[i].replies.length == 0) {
                            if (authorquerydata[i].repliesMark === undefined || authorquerydata[i].repliesMark === false) {
                                if (authorquerydata[i].aqCheck === undefined || authorquerydata[i].answerCheck === undefined || authorquerydata[i].answerCheck === 'no') {
                                    validitionlist.push({
                                        'serial': count + 1,
                                        'message': `${authorquerydata[i].label} : Please respond to the query before submitting.`,
                                        'position': 'Query'
                                    });
                                    count++;
                                }
                            }
                        }
                    }
                }
            } else {
                if (userdetails.role === 'Author' || userdetails.role === 'Editor') {
                    if (fileContent !== '') {
                        // Step 1: Split LaTeX content into lines
                        const latexLines = fileContent.split('\n');

                        // Step 2: Extract all citations with their line numbers
                        let citations = [];
                        latexLines.forEach((line, index) => {
                            const matches = line.match(/\\cite{([^}]+)}/g);
                            if (matches) {
                                matches.forEach(match => {
                                    const citationKeys = match.replace(/\\cite{|}/g, '').split(',').map(citation => citation.trim());
                                    citationKeys.forEach(citation => {
                                        citations.push({
                                            value: citation,   // citation value
                                            lineNumber: index + 1 // 1-based line number
                                        });
                                    });
                                });
                            }
                        });

                        // Step 3: Extract all bibitem entries with line numbers
                        let bibItems = [];
                        latexLines.forEach((line, index) => {
                            const bibItemMatch = line.match(/\\bibitem{([^}]+)}/);
                            if (bibItemMatch) {
                                bibItems.push({
                                    value: bibItemMatch[1].trim(),
                                    lineNumber: index + 1  // 1-based line number for the bibitem
                                });
                            }
                        });

                        // Step 4: Find missing citations (citations without bibitem entries)
                        let missingReferences = [];
                        citations.forEach(citation => {
                            if (!bibItems.some(bibItem => bibItem.value === citation.value)) {
                                missingReferences.push(citation);
                            }
                        });

                        // Step 5: Find bibitems without corresponding citations
                        let missingCitations = [];
                        bibItems.forEach(bibItem => {
                            const isCited = citations.some(citation => citation.value === bibItem.value);
                            if (!isCited) {
                                missingCitations.push(bibItem);
                            }
                        });

                        // Output missing citations with line numbers
                        missingReferences.forEach(missingRef => {
                            validitionlist.push({
                                'serial': count + 1,
                                'message': `Citation (${missingRef.value}) found without a corresponding reference.`,
                                'position': Number(missingRef.lineNumber)
                            });
                            count++;
                        });

                        // Output references without citations with their line numbers
                        missingCitations.forEach(missingCite => {
                            validitionlist.push({
                                'serial': count + 1,
                                'message': `Reference (${missingCite.value}) found without a corresponding citation.`,
                                'position': Number(missingCite.lineNumber)  // Line number for uncited reference
                            });
                            count++;
                        });
                    }
                }
            }

            // if (logContent != '') {
            //     let logLines = logContent.split('\n');
            //     logLines.forEach((line) => {
            //         if (line.includes("Unused label")) {
            //             validitionlist.push({
            //                 'serial': count + 1,
            //                 'message': `${line.trim()}`
            //             });
            //             count++;
            //         } else if (line.includes("Unused bibitem")) {
            //             validitionlist.push({
            //                 'serial': count + 1,
            //                 'message': `${line.trim()}`
            //             });
            //             count++;
            //         }
            //     });
            // }

            if (validitionlist.length === 0) {
                setConfirm(true);
            } else {
                props.validation(validitionlist);
                return validitionlist;
            }
        } catch (error) {
            console.error('Error validationcheck:', error);
        }
    }

    //set up interval save trigged
    // useEffect(() => {
    //         //Set up interval to auto-save every 5 seconds
    //         const intervalId = setInterval(() => {
    //             setSaveStatus(true)
    //         }, 60000);
    //         // Clean up the interval on component unmount
    //         return () => clearInterval(intervalId);
    // }, []);

    useEffect(() => {
        if (!props.editorOption) {
            if (saveStatus) {
                //Set up interval to auto-save every 5 seconds
                const intervalId = setInterval(() => {
                    if (saveButton.current) {
                        saveButton.current.click();
                    }
                }, 60000);
                // Clean up the interval on component unmount
                return () => clearInterval(intervalId);
            }
        }
    }, [saveStatus]);


    return (
        <div className="pdf-viewer">
            <Modal size="lg" show={showconfirm} aria-labelledby="contained-modal-title-vcenter" centered className="confirmation-popup">
                <Modal.Body>
                    <h1>Are you sure you want to submit?</h1>
                    <div className="change-group">
                        <div className="change-status">
                            <div className="change-status-inner">
                                <p>{userdetails.role} changes: {trackSpreate.deletion + trackSpreate.insertion}</p>
                                <div className="change-status-info">
                                    <p>Insertion: {trackSpreate.insertion}</p>
                                    <p>Deletion: {trackSpreate.deletion}</p>
                                </div>
                            </div>
                        </div>
                        <div className="change-status">
                            <div className="change-status-inner">
                                <p>Comments: {trackSpreate.Comment}</p>
                            </div>
                        </div>
                        <div className="change-status">
                            <div className="change-status-inner">
                                <p>Query Responses: {trackSpreate.AuthorQuery}</p>
                            </div>
                        </div>
                        <div className="change-status">
                            <div className="change-status-inner">
                                <p>Attachement: {trackSpreate.Attachement}</p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" style={{ marginRight: '10px' }} onClick={handleSubmit}>Yes</Button>
                    <Button variant="outline-primary" onClick={() => setConfirm(false)}>No</Button>
                </Modal.Footer>
            </Modal>
            <Modal size="md" show={showProgress} aria-labelledby="contained-modal-title-vcenter" centered className="confirmation-popup">
                <Modal.Body>
                    <div>
                        <p>{progressDetails}</p>
                        <span className="loader"></span>
                    </div>
                </Modal.Body>
            </Modal>
            <div className='pdf-btns-group'>
                <div>
                </div>
                <div>
                    <Button className="com-btn firststep" onClick={compileCode} ref={CompileButton}><BiRefresh /><span> {timer === true ? `Compile - ${formatTime(time)}` : 'Compile'}</span></Button>
                    <Button className="com-btn" onClick={onSave} ref={saveButton}><FaFloppyDisk /> Save</Button>
                    <Button className="com-btn tenthstep" onClick={validationcheck}><FaFloppyDisk /> Submit</Button>
                    <Button className="switchbutton" onClick={() => props.layoutview('editoronly')}> <FaCode /> Switch to editor</Button>
                </div>
            </div>
            {compileAction === true ?
                <div className="preview_animation">
                    <div className="loadPDF">
                        <p><u></u><u></u><u></u><u></u></p>
                        <p><u></u><u></u><u></u><u></u></p>
                        {pdfStatus === 'success' ? <FaCheckCircle className="success" /> :
                            pdfStatus === 'error' ? <FaExclamationCircle className="error" /> : ''}
                    </div>
                </div> : ""}
            <div className={showPdf ? "pdf-area active" : "pdf-area"}>
                <div className="pdf-action-group">
                    <button onClick={zoomIn} title="Zoom In"><FaSearchPlus /></button>
                    <button onClick={zoomOut} title="Zoom Out"><FaSearchMinus /></button>
                    <select value={zoomLevel} onChange={handleZoomChange}>
                        <option value={40}>40%</option>
                        <option value={60}>60%</option>
                        <option value={80}>80%</option>
                        <option value={100}>100%</option>
                        <option value={120}>120%</option>
                        <option value={140}>140%</option>
                        <option value={160}>160%</option>
                        <option value={180}>180%</option>
                        <option value={200}>200%</option>
                    </select>
                    {/* <button title="Pdf Download" onClick={pdfDownlad}><FaDownload /></button> */}
                </div>
                {downloadBar ?
                    <div className="pdf_download_popup_outer">
                        <div className="pdf_download_popup">
                            <p>Pdf download.</p>
                            <div className="progess_bar">
                                <div className="progess_bar_inner" style={{ width: `${downloadPercentage}%` }}></div>
                            </div>
                        </div>
                    </div> : ''}
                <div className="pdf-container" ref={containerRef} style={{ overflowX: 'scroll', overflowY: 'scroll' }} />
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isAuthorQuery: state.RightSidebar.AuthorQueryData,
        isComment: state.RightSidebar.Comments,
        isBlobComment: state.RightSidebar.blobCommentValue,
        isTrackChange: state.RightSidebar.TrackChangedData,
        isTextfromEditor: state.RightSidebar.EdiToPdf,
        isDocDetails: state.LayoutReducer.DocDetails
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchPdfLine: (lineText) => {
            console.log(lineText)
            dispatchPdfLine(dispatch, lineText)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PdfEditor);