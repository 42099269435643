import axios from "axios";
import pathconfig from '../../pathconfig.json';
import packjson from '../../../package.json';

const convertToBase64 = (str) => {
    return btoa(str);
};

export const styleEditing = async (value) => {
    try {
        let url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].retrievefiles}`;
        let GetDetailDataString = localStorage.getItem(`${value.docid}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);

        // 1. Check if folder exists
        let folderPath = GetDetailData.Articledetails.ArtResourcePath;

        let data = { InputPath: folderPath };
        let pts_file_name;
        let s5base64;
        let inibase64;

        // 2. Retrieve details and check if the folder contains files
        const fileResponse = await axios.post(url, data);
        console.log(fileResponse.data, 'response');

        // If no files are found in the supplementary folder
        if (fileResponse.data.length === 0) {
            return {
                error: true,
                message: `Supporting files missing in the Supplementary folder. Please contact Admin.`,
            };
        }
        // 3. Check if 'pts_order.xml' file exists
        let findData = fileResponse.data.filter((item) => item.path.includes("pts_order"));
        if (findData.length === 0) {
            return {
                error: true,
                message: `pts_order.xml file is missing in the Supplementary folder.`,
            };
        }
        pts_file_name = findData[0].path.split('/').pop();
        // 4. Check if pts_order file exists
        let ptsFilePath = `${folderPath}/${pts_file_name}`;
        let ptsFileData = { InputPath: ptsFilePath };
        let ptsFileExistResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`, ptsFileData);

        if (!ptsFileExistResponse.data.exist) {
            return {
                error: true,
                message: `${pts_file_name} file is missing in the Supplementary folder.`,
            };
        }
        // 5. Check if 'ELS_user_preedit-mapping.ini' file exists
        let iniFilePath = `${folderPath}/ELS_user_preedit-mapping.ini`;
        let iniFileData = { InputPath: iniFilePath };
        let iniFileExistResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].blobFileExistorNot}`, iniFileData);
        if (!iniFileExistResponse.data.exist) {
            return {
                error: true,
                message: `ELS_user_preedit-mapping.ini file is missing in the Supplementary folder.`,
            };
        }
        // 6. Fetch the XML data for the pts_order file
        const XmlData = { InputPath: ptsFilePath };
        const ptsFilePreviewResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_preview_file}`, XmlData);
        s5base64 = convertToBase64(ptsFilePreviewResponse.data);
        // 7. Fetch the ini file
        const iniData = { InputPath: iniFilePath };
        const iniFilePreviewResponse = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_preview_file}`, iniData);
        inibase64 = convertToBase64(iniFilePreviewResponse.data);
        // 8. Prepare request body for the final request
        let body = {
            texfilename: `${value.texfilename}.tex`,
            texcontent: convertToBase64(value.texcontent),
            s5filename: pts_file_name,
            s5content: s5base64,
            inifilename: 'ELS_user_preedit-mapping.ini',
            inicontent: inibase64,
        };

        let styleUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].styleEditing}`;
        // 9. Make the final axios request
        const response = await axios.post(styleUrl, body);
        let errorObjects = [];
        let textContent;
        // 10. Handle success and errors
        if (response.data.is_success === "true") {
            if (response.data.is_error_status === "true" || response.data.is_warning_status === "true") {
                let logContentBase64 = atob(response.data.data.logcontent);
                const regex = /\[(\d+:\d+)\]:\s(Error|Warning):\[[A-Z]+-\d+\]:\s(.+)/g;
                const matches = [...logContentBase64.matchAll(regex)];
                errorObjects = matches.map(match => ({
                    line: match[1].split(':')[0],
                    type: match[2],
                    message: match[3].trim(),
                }));
                if (response.data.is_warning_status === "true" && response.data.is_error_status === "false") {
                    textContent = atob(response.data.data.texcontent);
                }
            } else {
                textContent = atob(response.data.data.texcontent);
            }
            return { errorObjects, textcontent: textContent, responseData: response.data };
        } else {
            return { errorObjects, textcontent: textContent, responseData: response.data };
        }
    } catch (error) {
        console.log(error);
        return {
            error: true,
            message: error.message || 'An error occurred during the API call',
        };
    }
};

export const referanceOrdering = async (refValue, runningApi) => {
    let apiUrls = [
        `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].reforderStructuring}`,
        `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].authorStructuring}`,
        `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].pagerangeStructuring}`,
        `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].journalAbbreviation}`,
        `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].titleCasing}`,
        `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].refSortQuery}`
    ];

    let textcontent = refValue.texcontent;

    // Define body outside the loop
    let body = {
        'texfilename': `${refValue.texfilename}.tex`,
        'texcontent': convertToBase64(textcontent)  // Initial content, will be updated in the loop
    };

    let errorObjects = [];

    for (let i = 0; i < apiUrls.length; i++) {
        try {
            let apiname = apiUrls[i].split('/').pop() === "reforderStructuring" ? "Reforder Structuring" : apiUrls[i].split('/').pop() === "authorStructuring" ? "Author Structuring" : apiUrls[i].split('/').pop() === "pagerangeStructuring" ? "Page Range Structuring" : apiUrls[i].split('/').pop() === "journalAbbreviation" ? "Journal Abbreviation" : apiUrls[i].split('/').pop() === "titleCasing" ? "Title Casing" : apiUrls[i].split('/').pop() === "refSortQuery" ? "Ref Sort Query" : "";
            runningApi(apiname);

            // Decode for debugging
            let bodytexcontent = atob(body.texcontent);
            console.log(bodytexcontent, 'Decoded body.texcontent');

            // Make API request
            const response = await axios.post(apiUrls[i], body);
            const { data } = response;

            if (data.is_error_status === 'true') {
                // Handle errors
                let logContentBase64 = atob(data.data.logcontent);
                const regex = /\[(\d+:\d+)\]:\s(Error|Warning):\[[A-Z]+-\d+\]:\s(.+)/g;
                const matches = [...logContentBase64.matchAll(regex)];

                errorObjects = matches.map(match => ({
                    line: match[1].split(':')[0],
                    type: match[2],
                    message: match[3].trim()
                }));

                return {
                    errorObjects,
                    responseData: data,
                    api: apiUrls[i].split('/').pop()
                };
            } else {
                // Update texcontent for the next iteration
                textcontent = data.data.texcontent;  // Base64 encoded
                body.texcontent = textcontent;       // Update body with new content for next API call
            }

            // After the last API call, return the final data
            if (i === apiUrls.length - 1) {
                if (data.is_warning_status !== "false") {
                    let logContentBase64 = atob(data.data.logcontent);
                    const regex = /\[(\d+:\d+)\]:\s(Error|Warning):\[[A-Z]+-\d+\]:\s(.+)/g;
                    const matches = [...logContentBase64.matchAll(regex)];

                    errorObjects = matches.map(match => ({
                        line: match[1].split(':')[0],
                        type: match[2],
                        message: match[3].trim()
                    }));
                }

                return {
                    errorObjects,
                    texcontent: atob(data.data.texcontent),
                    responseData: data
                };
            }
        } catch (err) {
            // Catch any errors during API calls
            return {
                error: true,
                message: err.message || 'An error occurred during the API call',
                api: apiUrls[i].split('/').pop()
            };
        }
    }
};

export const clearCacheFiles = async (val) => {
    try {
        if (val !== undefined || val !== null) {
            let body = {
                'folderPath': `/home/ses-admin/texlive_demo/${val}`,
            };
            const cacheFile = await axios.post(`${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].cachefiledelete}`, body);
            return cacheFile.data;
        }
    } catch (err) {
        console.log(err)
    }
}