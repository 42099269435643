import { useEffect, useState } from "react";
import { FaTimes, FaEye, FaCloudDownloadAlt, FaRedo } from "react-icons/fa";
import pathconfig from '../../pathconfig.json';
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';
import { TfiReload } from "react-icons/tfi";
import { Modal, Button } from 'react-bootstrap';
import { toastError, toastSuccess, toastWarning } from '../toaster';
import packjson from '../../../package.json';

const HistoryTab = (props) => {
    const [historyData, setHistoryData] = useState([]);
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [fileContent, setFileContent] = useState(null);
    const [Docid, setDocid] = useState('');
    const [showconfirm, setShowconfirm] = useState(false)
    const [pathId, setpathId] = useState('');
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        if (props.leftsidebarvalue === 'history') {
            setLoader(true)
            const docid = location.search.split('?')[1].split('=')[1];
            setDocid(docid)
            getHistoryDetails(docid);
        }
    }, [props.leftsidebarvalue])


    // useEffect(() => {
    //     if (!props.fileReload) {
    //         const docid = location.search.split('?')[1].split('=')[1];
    //         getHistoryDetails(docid);
    //     }
    // }, [props.fileReload]);

    const getHistoryDetails = async (value) => {
        try {
            let url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_history_details}`;
            let GetDetailDataString = localStorage.getItem(`${value}_init-getdetails`);
            let GetDetailData = JSON.parse(GetDetailDataString);
            var data = {
                'ArticleGUID': GetDetailData.Articledetails.ArticleGUID,
                'ActivityID': GetDetailData.ActivtyDetails.ActivityID,
                'Proof_Type': GetDetailData.jobdetails.ProofType,
                'docid': value
            }
            const response = await axios.post(url, data);
            let file = [];
            response.data.data.map((item, i) => {
                let path = item.HistoryPath;
                let parts = path.split('/');
                let folderName = parts[3];
                let fileName = parts[4];
                let historyname = `${folderName}_${fileName}`;
                file.push({ 'historyname': historyname });
            })
            const mergedArray = response.data.data.map((item, index) => {
                return { ...item, ...file[index] };
            });
            setHistoryData(mergedArray)
            setLoader(false)
            toastSuccess('History details loaded successfully');
        } catch (error) {
            console.log(error)
            toastError('Failed to load history details');
            // const docid = location.search.split('?')[1].split('=')[1];
            // navigate(`/notification?docid=${docid}`, { state: { status: 'error', user: '' } })
        }
    }

    const filepreview = async (filepath) => {
        const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_preview_file}`;
        const data = {
            InputPath: filepath,
        };
        try {
            const isFileexist = await axios.post(url, data);
            if (isFileexist.data) {
                // code
                setFileContent(isFileexist.data);
                setShowModal(true); // Show modal when data is loaded
            } else {
                console.error('File does not exist.');
            }
        } catch (error) {
            console.error('Error fetching file:', error);
            toastError('File not found');
        }
    };

    const fileRevole = (pathId) => {
        setpathId(pathId);
        setShowconfirm(true)
    }

    const handleDeleteHistory = () => {
        let GetDetailDataString = localStorage.getItem(`${Docid}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);
        let url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].revokehistory}`;
        var data = {
            'Proof_Type': GetDetailData.jobdetails.ProofType,
            'docid': Docid,
            'hisID': pathId.hisID,
            'RId': pathId.RId
        }
        axios.post(url, data)
            .then((response) => {
                if (response.data.is_success) {
                    props.reloadData(response.data.is_success)
                    toastSuccess('History revoked successfully');
                }
                const docid = location.search.split('?')[1].split('=')[1];
                getHistoryDetails(docid);
                setShowconfirm(false)
            }).catch((error) => {
                console.log(error)
                toastError('Failed to revoke history');
                // const docid = location.search.split('?')[1].split('=')[1];
                // navigate(`/notification?docid=${docid}`, { state: { status: 'error', user: '' } })
            })
    }

    const fileDownload = async (item) => {
        let urlPath = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].download}`;
        const response = await axios.post(urlPath, {
            blobUrl: item.HistoryPath,
            type: 'application/x-tex'
        }, {
            responseType: 'blob',// Important               
        });
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/x-tex' }));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = item.historyname;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toastSuccess(`History file has been successfully downloaded.`);
    }

    const handleRefresh = () => {
        setLoader(true)
        const docid = location.search.split('?')[1].split('=')[1];
        setDocid(docid)
        getHistoryDetails(docid);
    }

    useEffect(() => {
        if (showModal) {
            const preElement = document.querySelector('.modal-footer pre');
            if (preElement) {
                preElement.scrollTop = 0;
            }
        }
    }, [showModal]);

    return (
        <div className="history-sidebar">
            <div className={`modal-outer ${showModal ? 'active' : ''}`}>
                <div className="modal">
                    <div className="modal-header">
                        File Preview
                        <button className="closebtn" title="Close" onClick={() => setShowModal(false)}><FaTimes /></button>
                    </div>
                    <div className="modal-footer" style={{ maxHeight: '525px' }}>
                        {fileContent ? <pre style={{ whiteSpace: 'pre-wrap', overflow: 'auto', margin: '0', scrollTop: 0 }}>{fileContent}</pre> : 'No content available'}
                    </div>
                </div>
            </div>
            <div className={`modal-outer ${showconfirm ? 'active' : ''}`}>
                <div className="modal confirmation-popup">
                    <div className="modal-body">
                        <h1 style={{ fontSize: '22px' }}>Are you sure you want to revert the article? </h1>
                        <p style={{ fontSize: '13px', margin: '0' }}>Note: This action cannot be undone</p>
                    </div>
                    <div className="modal-footer">
                        <button className="primary" onClick={handleDeleteHistory}>Yes</button>
                        <button className="outline-secondary" onClick={() => setShowconfirm(false)}>No</button>
                    </div>
                </div>
            </div>
            <div className="slider-header">
                <h5>History</h5>
                <div>
                    <button className="refresh-btn" onClick={handleRefresh} title="Refresh">
                        <FaRedo />
                    </button>
                    <button className="close-btn" onClick={props.close} title="Close">
                        <FaTimes />
                    </button>
                </div>
            </div>
            {loader ?
                <div className="history-loader">
                    <div className='fileupload-loder'><span className="loader"></span></div>
                </div> :
                <div className="history-inner">
                    {historyData.slice().reverse().map((item, i) =>
                        <div className="history-group" key={i}>
                            <h5>{moment(item.CreatedOn).format("DD MMMM YYYY - HH:mm:ss")}</h5>
                            <p title={item.historyname}>{item.historyname}</p>
                            <div className="history-btn">
                                {(props.userProfile.role !== 'Author' && props.userProfile.role !== 'Editor') ? <button title="Revoke" onClick={() => fileRevole(item)}><TfiReload /></button> : <button title="Download" onClick={() => fileDownload(item)}><FaCloudDownloadAlt /></button>}
                                <button title="View" onClick={() => filepreview(item.HistoryPath)}><FaEye /></button>
                            </div>
                        </div>
                    )}
                </div>}
        </div>
    )
}
export default HistoryTab;