import { useEffect, useState, useRef } from "react";
import { FaTimes, FaCloudUploadAlt, FaTrash, FaDownload } from "react-icons/fa";
import pathconfig from '../../../pathconfig.json';
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Docx from "../../../images/icons/docx.png";
import Pdf from "../../../images/icons/pdf.png";
import Jpg from "../../../images/icons/jpg.png";
import Png from "../../../images/icons/png.png";
import Tif from "../../../images/icons/tif.png";
import Tiff from "../../../images/icons/tiff.png";
import Zip from "../../../images/icons/zip.png";
import Xml from "../../../images/icons/xml.png";
import Json from "../../../images/icons/json.png";
import Ini from "../../../images/icons/ini.png";
import { toastError, toastSuccess } from '../../toaster';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import NodataFound from "../../nodata";
import { connect } from "react-redux";
import packjson from '../../../../package.json';

const Attachment_Sidebar = (props) => {
    const [file, setFile] = useState([]);
    const fileInputRef = useRef(null);
    const location = useLocation();
    const [showconfirm, setConfirm] = useState(false)
    const [deleteData, setDeleteData] = useState('');
    const [loader, setLoader] = useState(true);
    const [DocId, setDocid] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        setDocid(props.isDocDetails.docid)
    }, [props.isDocDetails]);

    useEffect(() => {
        if (props.fileItems) {
            setFile(props.fileItems);
            setLoader(false)
        }
        console.log(props.fileItems, 'props.fileItems')
    }, [props.fileItems])



    const handleFileupload = (e) => {
        setLoader(true)
        const selectedFiles = Array.from(e.target.files);
        const selectedFiles2 = file.filter((a) =>
            selectedFiles.some((sf) => sf.name === a.Filename)
        );
        if (selectedFiles2.length !== 0) {
            toastError('File is already exit.');
            setLoader(false);
        } else {
            try {
                let GetDetailDataString = localStorage.getItem(`${DocId}_init-getdetails`);
                let GetDetailData = JSON.parse(GetDetailDataString);
                var formdata = new FormData();
                formdata.append("jobGUID", GetDetailData.Articledetails.ArticleGUID);
                formdata.append("ActivityID", GetDetailData.ActivtyDetails.ActivityID);
                formdata.append("docid", DocId);
                formdata.append("type", "attachment");
                // for (let i = 0; i < e.target.files.length; i++) {
                //     formdata.append("files", e.target.files[i]);
                // }
                const oversizedFiles = [];
                const maxFileSize = 40 * 1024 * 1024; // 25MB in bytes
                const validFiles = [];
                for (let i = 0; i < e.target.files.length; i++) {
                    if (e.target.files[i].size > maxFileSize) {
                        oversizedFiles.push(e.target.files[i].name);
                    } else {
                        validFiles.push(e.target.files[i]);
                    }
                }
                validFiles.forEach(file => formdata.append("files", file));
                console.log(validFiles.length)
                if (oversizedFiles.length > 0) {
                    toastError(`File size should be less than 40 MB: ${oversizedFiles} `);
                    setLoader(false)
                }
                if (oversizedFiles.length > 0 && validFiles.length == 0) {
                    e.target.value = null;
                    return;
                }
                const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].upload_attachment}`;
                axios.post(url, formdata, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }
                ).then((response) => {
                    if (response.data.SuccessFiles.length !== 0) {
                        response.data.SuccessFiles.map((item) =>
                            toastSuccess(`${item.data[0].Filename} - ${item.message}`)
                        )
                    }
                    if (response.data.FailedFiles.length !== 0) {
                        response.data.FailedFiles.map((item) =>
                            toastError(`${item.data[0].Filename} - ${item.message}`)
                        )
                    }
                    if (response.data.success === true) {
                        props.getTrigger(true)
                        //getDetails()
                    }
                }
                ).catch(error => {
                    console.log(error)
                    toastError(error.message);
                    setLoader(false);
                    //navigate(`/notification?docid=${DocId}`, { state: 'error' })

                })
            } catch (error) {
                console.log(error)
                toastError(error.message);
                setLoader(false);
                // navigate(`/notification?docid=${DocId}`, { state: 'error' })
            }
        }
        e.target.value = null;
    }

    const deleteConfirm = (value) => {
        setDeleteData(value)
        setConfirm(true)
    }
    const deleteAttchment = () => {
        props.getTrigger(false)
        let dataValue = deleteData;
        let GetDetailDataString = localStorage.getItem(`${DocId}_init-getdetails`);
        let GetDetailData = JSON.parse(GetDetailDataString);
        let data = {
            "ArticleGUID": dataValue.ArticleGUID,
            "AttachID": dataValue.AttachID,
            "Proof_Type": GetDetailData.jobdetails.ProofType,
            "AttachFlag": dataValue.AttachFlag,
            "docid": DocId
        }
        const deleteUrl = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].deleteAttachment}`
        axios.post(deleteUrl, data).then((response) => {
            setConfirm(false)
            toastSuccess(`${response.data.data[0].Filename} - ${response.data.message}`)
            props.getTrigger(true)
            //getDetails()
        }
        ).catch((error) => {
            console.log(error);
            toastError(error.message);
            //navigate(`/notification?docid=${DocId}`, { state: 'error' })
        })
    }

    return (
        <div className="attachement_area">
            <div className="slider-header">
                <h5>Attachement</h5>
                <div>
                    <button className="close-btn" onClick={props.close} title="Close">
                        <FaTimes />
                    </button>
                </div>
            </div>
            <div className={`modal-outer ${showconfirm ? 'active' : ''}`}>
                <div className="modal confirmation-popup">
                    <div className="modal-body">
                        <h1>Are you want to delete this file?</h1>
                    </div>
                    <div className="modal-footer">
                        <button className="primary" onClick={deleteAttchment}>Yes</button>
                        <button className="outline-secondary" onClick={() => setConfirm(false)}>No</button>
                    </div>
                </div>
            </div>
            <div className="upload-area">
                <div className="upload-btn-wrapper">
                    <FaCloudUploadAlt />
                    <p>Choose file or drag here</p>
                    <input type="file" onChange={(e) => handleFileupload(e)} multiple ref={fileInputRef} />
                </div>
                <div className="upload-list-area">
                    {loader ? <div className='fileupload-loder'><span className="loader"></span></div> : ''}
                    {file.length !== 0 ? file.map((item, index) =>
                        <div className="upload-list-area-inner" key={index}>
                            <div className="upload-icons">
                                <img src={item.filetype === "png" ? Png : item.filetype === "pdf" ? Pdf : item.filetype === "jpg" || item.filetype === "jpeg" ? Jpg : item.filetype === "docx" ? Docx : item.filetype === "tiff" ? Tiff : item.filetype === "tif" ? Tif : item.filetype === "zip" ? Zip : item.filetype === "json" ? Json : item.filetype === "xml" ? Xml : item.filetype === "ini" ? Ini : ""} alt={item.filetype} />
                            </div>
                            <div className="upload-details">
                                <p title={item.Filename}>{item.Filename}</p>
                                <label>{item.username}</label>
                                <span>Date: {moment(item.CreatedOn).format("DD MMM YY")}, Size: {item.Filesize}</span>
                                <a href={item.SASFilepath} download={item.Filename}
                                    className="close-btn" title="Download" style={{ right: '21px' }}>
                                    <FaDownload />
                                </a>
                                <button className="close-btn" title="delete" onClick={() => deleteConfirm(item)}>
                                    <FaTrash />
                                </button>
                            </div>
                        </div>
                    ) : <NodataFound />}
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isDocDetails: state.LayoutReducer.DocDetails
    }
}
export default connect(mapStateToProps, null)(Attachment_Sidebar);