// HighlightContext.js
import { createContext, useContext, useState } from 'react';

const HighlightContext = createContext();

export const HighlightProvider = ({ children }) => {
    const [highlights, setHighlights] = useState(null);
    const [linepostion, setLinePostion] = useState(null);
    const [editor, setEditor] = useState(null);
    const [mainView, setMainView] = useState(null);
    return (
        <HighlightContext.Provider value={{ highlights, setHighlights, linepostion, setLinePostion, editor, setEditor, mainView, setMainView }}>
            {children}
        </HighlightContext.Provider>
    );
};

export const useHighlightContext = () => useContext(HighlightContext);
