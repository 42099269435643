export const Focus = "Focus";
export const AQchange = "AQchange";
export const AQdelete = "AQdelete";
export const ViewDoc = "ViewDoc";
export const DocDetailsData = "DocDetailsData";
export const sidebarCommentAction = "sidebarCommentAction";
export const commentValueAction = "commentValueAction";
export const BLOB_COMMENT = 'BLOB_COMMENT';
export const CommentAction = "CommentAction";
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const FOCUS_COMMENT = 'FOCUS_COMMENT';
export const Trackchange_Data = 'Trackchange_Data';
export const Trackchange_Blob = 'Trackchange_Blob';
export const TrackchangeAction = 'TrackchangeAction';

export const AQData = "AQData";
export const LayoutData = "LayoutData";

//editor
export const Editor_Value_Change = "Editor_Value_Change";

//pdftext
export const PDFText = "PDFText";

// editor to pdf text
export const EdiToPdf = "EdiToPdf";

// export const TextoLayout = "TextoLayout"