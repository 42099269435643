import { EditorView } from '@codemirror/view'
import {Compartment,
  EditorState,
  Extension,
  StateEffect,
  StateField,
  TransactionSpec,} from '@codemirror/state'
import { sendMB } from '../../../infrastructure/event-tracking.js'
// import { isVisual } from '../../visual/visual.ts'

export function emitCommandEvent(
  view: EditorView,
  key: string,
  command: string
) {
  const mode = isVisual(view) ? 'visual' : 'source'
  sendMB(key, { command, mode })
}
export const toggleVisualEffect = StateEffect.define<boolean>()
const visualState = StateField.define<boolean>({
  create() {
    return false;
  },
  update(value, tr) {
    for (const effect of tr.effects) {
      if (effect.is(toggleVisualEffect)) {
        return effect.value
      }
    }
    return value
  },
})
const isVisual = (view: EditorView) => {
  return view.state.field(visualState, false) || false
}
export function emitToolbarEvent(view: EditorView, command: string) {
  emitCommandEvent(view, 'codemirror-toolbar-event', command)
}
