import { FaExclamationCircle, FaCheckCircle } from "react-icons/fa";
import DocImage from "../../images/doc-icons.gif";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaLink } from "react-icons/fa6";
import { connect } from "react-redux";
const Notification = (props) => {
    const [status, setStatus] = useState('success')
    const [countdown, setCountdown] = useState(5);
    const [role, setRole] = useState('')
    const location = useLocation();

    useEffect(() => {       
        setStatus(location.state)
   }, [])

    useEffect(()=>{
        setRole(props?.isDocDetails?.role)
    },[props.isDocDetails])

    // useEffect(() => {
    //     let timer;
    //     if (status !== 'error') {
    //         timer = setInterval(() => {
    //             setCountdown(prevCountdown => {
    //                 if (prevCountdown <= 1) {
    //                     clearInterval(timer);
    //                     window.location.href = 'https://www.surveymonkey.com/r/HMBCDK5?JID=ELS&AID=33BAF209';
    //                     return 0;
    //                 }
    //                 return prevCountdown - 1;
    //             });
    //         }, 1000);
    //     }
    //     return () => clearInterval(timer);
    // }, [status]);

    function closeCurrentTab() {
        // Close the current tab
        window.close();
    }

    return (
        <div className="notifaction">
            <div className="notification-inner">
                <div className="notifaction-img">
                    <img src={DocImage} alt="doc" />
                    {status === 'error' ? <FaExclamationCircle className="error" /> : <FaCheckCircle className="success" />}
                </div>
                <h2>{status === 'error' ? 'An unexpected exception occurred.' : 'The document has been saved and submitted successfully.'}</h2>
                {/* {status !== 'error' && <h5>Will navigate to the survey in {countdown} sec</h5>} */}
                {status !== 'error' ?
                    <>
                        <p>Thank you for returning your proof corrections. We would value your feedback on your proofing experience.<br></br> Please take a few moments to complete this survey.</p>
                        {role === "Author" ? <a href="https://www.surveymonkey.com/r/HMBCDK5?JID=ELS&AID=33BAF209"><FaLink /> https://www.surveymonkey.com/r/HMBCDK5?JID=ELS&AID=33BAF209</a> : ""}
                        <br></br>
                        <Button variant="primary" style={{ margin: '10px 0', fontSize: '13px' }} onClick={() => closeCurrentTab()}>{role === "Author" ? 'Skip and Close' : 'Close'}</Button>
                    </> :
                    <>
                        <p>Please contact our support team in case of any assistance.</p>
                        <p className="support">By email : <a href="mailto:product.support@integra.co.in">product.support@integra.co.in</a></p>
                    </>}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isDocDetails: state.LayoutReducer.DocDetails
    }
}
export default connect(mapStateToProps, null)(Notification);
