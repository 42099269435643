import { useEffect, useState, useRef } from "react";
import { FaTimes, FaEye, FaSearchPlus, FaSearchMinus, FaCloudUploadAlt } from "react-icons/fa";
import pathconfig from '../../pathconfig.json';
import axios from "axios";
import { toastError, toastSuccess, toastWarning } from '../toaster';
import Pdf from "../../images/icons/pdf.png";
import Jpg from "../../images/icons/jpg.png";
import Png from "../../images/icons/png.png";
import Tif from "../../images/icons/tif.png";
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import NodataFound from "../nodata";
import { useNavigate, useLocation } from "react-router-dom";
import packjson from '../../../package.json';

const ImagelistTab = (props) => {
    const [fileList, setFileList] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [imgPathValue, setimgPathValue] = useState({});
    const [imgZoom, setImgZoom] = useState(1);
    const [Texdata, setTexdata] = useState([]);
    const [loader, setLoader] = useState(false)
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (props.texData) {
            setTexdata(props.texData);
            const regex = /(?<!.)\\graphicspath\{\{([^}]*)\}\}/;
            const match = props.texData.Content.match(regex);
            if (match) {
                const path = match[1];
                let changePath = path.replace(/\/$/, "");
                getFile_details(changePath)
            } else {
                toastError('No graphicPath found.');
                return;
            }
        }
    }, [props.texData])

    // useEffect(() => {
    //     if (props.pdfData) {

    //     }
    //     //console.log(props.pdfData, props.valueChange, 'props.pdfData, props.valueChange')
    // }, [props.pdfData])

    const getFile_details = async (link) => {
        try {
            const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].get_image_list}`;
            const data = { file_path: link };
            const response = await axios.post(url, data);
            setFileList(response.data.Images);
        } catch (error) {
            console.log(error);
            toastError(error.message);
            // const docid = location.search.split('?')[1].split('=')[1];
            // navigate(`/notification?docid=${docid}`, { state: 'error' });
        }
    }


    const imageView = (val) => {
        try {
            setImgZoom(1);
            setimgPathValue(val);
            setShowModal(true);
        } catch (error) {
            console.log(error);
            toastError('Error viewing image');
        }
    }


    const handleZoom = (type) => {
        try {
            let size = 2;
            let scaleEnd = 0.5;
            if (type === 'plus') {
                if (size !== imgZoom) {
                    setImgZoom(imgZoom + 0.5);
                }
            } else {
                if (scaleEnd !== imgZoom) {
                    setImgZoom(imgZoom - 0.5);
                }
            }
        } catch (error) {
            console.log(error);
            toastError('Error adjusting zoom');
        }
    }


    const handleFileReupload = (e) => {
        setLoader(true)
        try {
            var formdata = new FormData();
            const oversizedFiles = [];
            const maxFileSize = 40 * 1024 * 1024; // 25MB in bytes
            const validFiles = [];
            for (let i = 0; i < e.target.files.length; i++) {
                if (e.target.files[i].size > maxFileSize) {
                    oversizedFiles.push(e.target.files[i].name);
                } else {
                    validFiles.push(e.target.files[i]);
                }
            }
            validFiles.forEach(file => formdata.append("files", file));
            console.log(validFiles.length)
            if (oversizedFiles.length > 0) {
                toastError(`File size should be less than 40 MB: ${oversizedFiles} `);
                setLoader(false)
            }
            if (oversizedFiles.length > 0 && validFiles.length == 0) {
                e.target.value = null;
                return;
            }
            const regex = /(?<!.)\\graphicspath\{\{([^}]*)\}\}/;
            const match = Texdata.Content.match(regex);
            if (match) {
                const path = match[1];
                formdata.append("path", path);
            } else {
                toastError('No graphicPath found.');
                return;
            }
            const url = `${pathconfig[packjson.environment].baseUrl}${pathconfig[packjson.environment].figureAttachment}`;
            axios.post(url, formdata, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
            ).then((response) => {
                if (response.data.success === true) {
                    toastSuccess(`${response.data.message}`)
                    setLoader(false)
                    props.getTrigger(true)
                    //getDetails()                    
                }
            }
            ).catch(error => {
                console.log(error)
                toastError(error.message);
                setLoader(false);
                // const docid = location.search.split('?')[1].split('=')[1];
                // navigate(`/notification?docid=${docid}`, { state: 'error' })
            })
        } catch (error) {
            console.log(error);
            toastError('Error uploading file');
            setLoader(false);
            e.target.value = null;
        }
        // e.target.value = null;
    }

    return (
        <div className="image-list-area">
            {Object.keys(imgPathValue).length ?
                <div className={`modal-outer ${showModal ? 'active' : ''}`} >
                    <div className="modal" style={{ width: '800px' }}>
                        <div className="modal-header">
                            Image Preview - {imgPathValue.name.split('.')[0]}
                            <button className="closebtn" title="Close" onClick={() => setShowModal(false)}><FaTimes /></button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: '525px', overflow: 'hidden', position: 'relative' }}>
                            <div className="image-preview-area">
                                <button onClick={() => handleZoom('plus')}><FaSearchPlus /></button>
                                <button onClick={() => handleZoom('minus')}><FaSearchMinus /></button>
                            </div>
                            <div className="img-preview-area">
                                <img src={imgPathValue.thumbnail_base64} style={{ width: '100%', height: 'auto', transform: `scale(${imgZoom})`, transformOrigin: '0 0' }} alt={imgPathValue.name.split('.')[0]} />
                            </div>
                        </div>
                    </div>
                </div> : ""}
            <div className="slider-header">
                <h5>Image List</h5>
                <div>
                    <button className="close-btn" onClick={props.close} title="Close">
                        <FaTimes />
                    </button>
                </div>
            </div>
            <div className="upload-list-area">
                {fileList.length !== 0 ? fileList.map((item, index) =>
                    <div className="upload-list-area-inner" key={index} onClick={() => imageView(item)} style={{ cursor: 'pointer' }}>
                        <div className="upload-icons">
                            <img src={Jpg} alt='file' />
                        </div>
                        <div className="upload-details">
                            <p title={item.name.split('.')[0]}>{item.name.split('.')[0]}</p>
                            {/* <span>Date: {moment(item.CreatedOn).format("DD MMM YY")}, Size: {(item.size / 1024).toFixed(2)} kb</span> */}
                            <span>Date: {moment(item.CreatedOn).format("DD MMM YY")}</span>
                            {/* <button className="view-btn" title="View" onClick={() => imageView(item)}><FaEye /></button> */}
                        </div>
                    </div>
                ) : <NodataFound />}
            </div>
            {loader ? <div className='fileupload-loder'><span className="loader"></span></div> : ''}
            <div className="reupload-area">
                <div className="reupload-area-inner">
                    <FaCloudUploadAlt />
                    <p>add or reupload - Choose file or drag here</p>
                    <input type="file" accept=".pdf" onChange={(e) => handleFileReupload(e)} multiple ref={fileInputRef}></input>
                </div>
            </div>
        </div>
    )
}
export default ImagelistTab;