import { Focus, AQchange, AQdelete, CommentAction, DELETE_COMMENT, FOCUS_COMMENT, AQData, sidebarCommentAction, commentValueAction, BLOB_COMMENT, Trackchange_Data, TrackchangeAction, Trackchange_Blob, PDFText, EdiToPdf, TextoLayout } from "../action/action";

const initialState = {
    Focus: 0,
    AQchange: { linenumber: 0, oldcontent: "", changecontent: "" },
    AQdelete: { linenumber: 0, oldcontent: "" },
    Comments: [],
    AuthorQuery: [],
    DELETE_COMMENT: [],
    FOCUS_COMMENT: [],
    AuthorQueryData: [],
    commentSidebar: [],
    CommentValue: [],
    blobCommentValue: [],
    TrackChanged: [],
    TrackChangedData: [],
    TrackActionData: [],
    PDFText: [],
    EdiToPdf: [],
    // TextoLayout: []

};

const RightSidebar = (state = initialState, action) => {
    switch (action.type) {
        case Focus:
            return { ...state, Focus: action.Focus };
        case AQchange:
            return { ...state, AQchange: action.AQchange };
        case AQdelete:
            return { ...state, AQdelete: action.AQdelete };
        case CommentAction:
            return { ...state, Comments: action.CommentValue };
        case DELETE_COMMENT:
            return { ...state, DELETE_COMMENT: action.DeleteValue };
        case FOCUS_COMMENT:
            return { ...state, FOCUS_COMMENT: action.FocusValue };
        case AQData:
            return { ...state, AuthorQueryData: action.AuthorQuery };
        case sidebarCommentAction:
            return { ...state, commentSidebar: action.commentPass };
        case commentValueAction:
            return { ...state, CommentValue: action.commentValuePass };
        case BLOB_COMMENT:
            return { ...state, blobCommentValue: action.BLOB_COMMENT_DATA };
        case Trackchange_Data:
            return { ...state, TrackChanged: action.TrackchangeData };
        case TrackchangeAction:
            return { ...state, TrackActionData: action.TrackAction };
        case Trackchange_Blob:
            return { ...state, TrackChangedData: action.TrackchangeBlob };
        case PDFText:
            return { ...state, PDFText: action.PDFText };
        case EdiToPdf:
            return { ...state, EdiToPdf: action.EdiToPdf };
        // case TextoLayout:
        //     return { ...state, TextoLayout: action.TextoLayout };
        default:
            return state;
    }
}

export default RightSidebar;